import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrowRounded";

import { racehorse360 } from "@tsg/1st-grpc-web";
import routes from "common/routes";
import Breakpoints from "common/breakpoints";
import AppSearch from "components/AppSearch/AppSearch";
import { useExamLockerContext } from "components/ExamLockerContext/ExamLockerContext";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import SelectHorseDialog from "../../SelectHorseDialog";
import WarningMessageDialog from "./WarningMessageDialog/WarningMessageDialog";
import useStyles from "./styles";

export interface Props {
  horse: racehorse360.IHorse;
  onOpenExamPage: () => void;
  isExistingExam: boolean;
  warningMessage: racehorse360.IRelevantHorseWarningMessage;
  isPullRelevantHorseWarningMessageFetching: boolean;
  refetchHorseWarningMessage: () => void;
}

const PageHeader = (props: Props) => {
  const {
    horse,
    onOpenExamPage,
    isExistingExam,
    warningMessage,
    isPullRelevantHorseWarningMessageFetching,
    refetchHorseWarningMessage
  } = props;
  const classes = useStyles();
  const { isLocked, pullLocker } = useExamLockerContext();

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { currentUser } = useLoggedInUser();
  const history = useHistory();
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down(Breakpoints.SM_600), {
    noSsr: true
  });
  const matchesSX720 = useMediaQuery(
    theme.breakpoints.down(Breakpoints.SX_720),
    {
      noSsr: true
    }
  );
  const shouldShowStartExamButton =
    !currentUser.isRegulatoryVet && horse.currentFacility?.id;

  const handleRedirect = (horseId: string) => {
    history.push(routes.horseDetails.path.replace(":horseId", String(horseId)));
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleExamButtonClick = () => {
    pullLocker().then(response => {
      if (!response.data.isLocked) {
        onOpenExamPage();
      }
    });
  };

  const renderHorseName = () => {
    if (!horse.name?.length) {
      return (
        <Typography className={clsx(classes.name, classes.emptyHorseName)}>
          Horse Name
        </Typography>
      );
    }

    if (matchesXS) {
      return (
        <div
          role={"button"}
          tabIndex={0}
          className={classes.horseNameButton}
          onClick={handleDialogOpen}
        >
          <Typography className={classes.name}>{horse.name}</Typography>
          <PlayArrowIcon
            className={clsx(classes.arrowIcon, {
              [classes.arrowIconReversed]: openDialog
            })}
          />
        </div>
      );
    }

    return <Typography className={classes.name}>{horse.name}</Typography>;
  };

  const renderStartExamButtonTitle = () => {
    if (matchesSX720) return null;

    return isExistingExam ? "Continue Exam" : "Start Exam";
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid container className={classes.leftPart}>
          <Typography className={classes.currentlyViewing}>
            Currently Viewing
          </Typography>
          <Grid className={classes.horseData}>
            <WarningMessageDialog
              className={classes.warningButton}
              horse={horse}
              warningMessage={warningMessage}
              isPullRelevantHorseWarningMessageFetching={
                isPullRelevantHorseWarningMessageFetching
              }
              refetchHorseWarningMessage={refetchHorseWarningMessage}
            />
            {renderHorseName()}
          </Grid>
        </Grid>

        {!matchesXS && (
          <AppSearch
            placeholder={"Search Horses"}
            classNames={{
              root: classes.rootSearch
            }}
            onRedirect={handleRedirect}
            isRedirect
          />
        )}

        {shouldShowStartExamButton && (
          <Button
            className={classes.startExamButton}
            variant="outlined"
            onClick={handleExamButtonClick}
            startIcon={matchesSX720 && <PlayArrowRoundedIcon />}
            disabled={isLocked}
            data-test-id={"examButton"}
          >
            {renderStartExamButtonTitle()}
          </Button>
        )}
      </Grid>

      {matchesXS && (
        <SelectHorseDialog
          open={openDialog}
          onClose={handleDialogClose}
          onRedirect={handleRedirect}
          horse={horse}
        />
      )}

      {/*Overlay for back arrow button to close SelectHorseDialog in according with RAC-926*/}
      {matchesXS && openDialog && (
        <div
          role={"button"}
          tabIndex={0}
          className={classes.closeOnBackArrow}
          onClick={handleDialogClose}
        />
      )}
    </>
  );
};

export default PageHeader;
