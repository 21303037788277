import React from "react";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import WarningIcon from "@material-ui/icons/Warning";
import Button from "@material-ui/core/Button";

import { useRacehorse360Api } from "hooks/api";
import Loader from "components/Loader";
import { getUniqueCheckedHorses } from "../helper";

import useStyles from "./styles";

export interface ISelectedHorsesWithRiskFactors {
  horseId: string;
  horseName: string;
  riskFactorsCount: number;
  trainerName: string;
}

interface IProps {
  open: boolean;
  onClose: () => void;
  onSaveRequestsSelection: () => void;
  workoutRequestQuery: racehorse360.IWorkoutRequestFilter;
  examCheckedIds: string[];
  passCheckedIds: string[];
}

const SHOWN_HORSES_COUNT = 3;

const ConfirmSelectionsDialog = (props: IProps) => {
  const {
    open,
    onClose,
    onSaveRequestsSelection,
    workoutRequestQuery,
    examCheckedIds,
    passCheckedIds
  } = props;

  const classes = useStyles();

  const selectedWorkoutsRequests = [
    ...new Set([...passCheckedIds, ...examCheckedIds])
  ];

  const { useListWorkoutRequests } = useRacehorse360Api();

  const { isLoading, data } = useListWorkoutRequests({
    query: {
      ...workoutRequestQuery,
      ids: selectedWorkoutsRequests
    },
    pagingOptions: {
      maxResults: selectedWorkoutsRequests.length
    },
    getOptions: {
      select: [
        "id",
        "horse.id",
        "horse.name",
        "horse.trainer.firstName",
        "horse.trainer.lastName",
        "horse.riskFactorsCount"
      ]
    }
  });

  const selectedHorsesWithRiskFactors = getUniqueCheckedHorses(
    data?.workoutRequests || []
  );

  const shouldShowAlertBlock = selectedHorsesWithRiskFactors.some(
    h => h.riskFactorsCount
  );
  const shouldShowMessageAboutRestHorses =
    selectedHorsesWithRiskFactors.length > SHOWN_HORSES_COUNT;
  const isPlural = selectedHorsesWithRiskFactors.length !== 1;

  const handleConfirm = () => {
    onSaveRequestsSelection();
    onClose();
  };

  const renderHorsesList = () => {
    return selectedHorsesWithRiskFactors
      .slice(0, SHOWN_HORSES_COUNT)
      .map(horse => {
        return (
          <div key={`${horse.horseId}`} className={classes.horseItem}>
            <div>
              <div className={classes.horseProperty}>{horse.horseName}</div>
              <div className={classes.horseProperty}>{horse.trainerName}</div>
            </div>
            <div className={classes.risksCount}>
              {`${horse.riskFactorsCount} ${isPlural ? "Risks" : "Risk"}`}
            </div>
          </div>
        );
      });
  };

  if (isLoading)
    return <Loader className={classes.confirmSelectionsLoader} overlay />;

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <div className={classes.title}>Confirm Selections?</div>
        <div className={classes.selections}>
          <span className={classes.selectionsNumber}>
            {`(${passCheckedIds.length})`}&nbsp;
          </span>
          <span>&ndash; Quick Pass</span>
        </div>
        <div className={classes.selections}>
          <span className={classes.selectionsNumber}>
            {`(${examCheckedIds.length})`}&nbsp;
          </span>
          <span>&ndash; Exams</span>
        </div>
      </DialogTitle>

      {shouldShowAlertBlock && (
        <DialogContent
          className={clsx(classes.dialogContent, {
            ["withExtraBottomMargin"]: shouldShowMessageAboutRestHorses
          })}
        >
          <div className={classes.alertHeader}>
            <WarningIcon className={classes.warningIcon} />
            <span className={classes.alertTitle}>Risk Factor Alert</span>
          </div>
          <div className={classes.warningMessage}>
            You have chosen a horse(s) with one or more potential risk factors.
          </div>
          <div
            className={clsx({
              [classes.horsesList]: shouldShowMessageAboutRestHorses
            })}
          >
            {renderHorsesList()}
          </div>
          {shouldShowMessageAboutRestHorses && (
            <div className={classes.messageAboutRestHorses}>
              {`${
                selectedHorsesWithRiskFactors.length - SHOWN_HORSES_COUNT
              } More Horses w/ Potential Risks…`}
            </div>
          )}
        </DialogContent>
      )}

      <DialogActions className={classes.dialogActions}>
        <Button
          className={classes.button}
          onClick={onClose}
          data-test-id={"goBackButton"}
        >
          Go Back
        </Button>
        <Button
          className={clsx(classes.button, classes.confirmButton)}
          onClick={handleConfirm}
          data-test-id={"confirmButton"}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmSelectionsDialog;
