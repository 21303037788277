import React from "react";
import { useDispatch } from "react-redux";

import ActionColumnHeaders from "components/VetExamsTable/TableHeader/ActionColumnHeaders";
import TableHeader from "components/VetExamsTable/TableHeader";
import { getSortDirection } from "components/VetExamsTable/TableHeader/helper";
import { ITableHeaderColumnsContent } from "components/VetExamsTable/types";
import { useAppSelector } from "hooks/redux/useAppSelector";
import { checkSortingType } from "utils/sort-utils";
import { SortOrder, SortOrderType } from "interfaces/SortOrder";
import {
  setOrder as setStoreOrder,
  setOrderBy as setStoreOrderBy
} from "store/actions/vetWorkoutsPage";
import { EColumn } from "../WorkoutRow/helper";
import { EColumnName } from "../options";
import { checkCurrentWorkoutsTableTab } from "../../helper";

interface IProps {
  columnsClassName: string;
  columns: string[];
  refElement: HTMLElement;
}

const REQUESTS_TAB_ACTION_COLUMNS = ["Exam", "Pass", "Reject"];
const EXAMS_TAB_ACTION_COLUMNS = ["Assign"];

const WorkoutsTableHeader = (props: IProps) => {
  const { columnsClassName, columns, refElement } = props;

  const dispatch = useDispatch();
  const { selectedTab, order, orderBy } = useAppSelector(
    state => state.vetWorkoutsPage
  );

  const { isFailedTab, isPassedTab, isRequestsTab, isExamsTab, isRejectedTab } =
    checkCurrentWorkoutsTableTab(selectedTab.value);
  const shouldShowAssignedVetColumn = [
    isPassedTab,
    isFailedTab,
    isExamsTab
  ].some(Boolean);

  const handleSort = (
    columnName: EColumnName,
    sortDirection: SortOrderType
  ) => {
    const { isAsc } = checkSortingType(sortDirection);
    const sortOrder = isAsc ? SortOrder.ASC : SortOrder.DESC;

    dispatch(setStoreOrderBy(columnName));
    dispatch(setStoreOrder(sortOrder));
  };

  const tableHeaderColumnsContent: ITableHeaderColumnsContent = {
    [EColumn.TRAINER]: [
      {
        title: "Horse",
        isDisplayed: true,
        sortableCell: {
          name: EColumnName.HORSE_NAME,
          sortOrder: getSortDirection(EColumnName.HORSE_NAME, orderBy, order),
          sortHandler: handleSort
        }
      },
      {
        title: "Trainer",
        isDisplayed: true,
        sortableCell: {
          name: EColumnName.TRAINER_LASTNAME,
          sortOrder: getSortDirection(
            EColumnName.TRAINER_LASTNAME,
            orderBy,
            order
          ),
          sortHandler: handleSort
        }
      }
    ],
    [EColumn.HISTORY]: {
      title: "History",
      isDisplayed: isRequestsTab || isExamsTab,
      centered: true
    },
    [EColumn.WORK_DATE]: {
      title: "Work Date",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EColumnName.WORKOUT_REQUEST_DATE,
        sortOrder: getSortDirection(
          EColumnName.WORKOUT_REQUEST_DATE,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EColumn.REQUESTED]: {
      title: "Requested",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EColumnName.WORKOUT_REQUEST_CREATION_DATE,
        sortOrder: getSortDirection(
          EColumnName.WORKOUT_REQUEST_CREATION_DATE,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EColumn.RISK_LEVEL]: {
      title: "Risk Level",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EColumnName.HEALTH_WATCH_STATUS,
        sortOrder: getSortDirection(
          EColumnName.HEALTH_WATCH_STATUS,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EColumn.BARN]: {
      title: "Barn #",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EColumnName.BARN_NAME,
        sortOrder: getSortDirection(EColumnName.BARN_NAME, orderBy, order),
        sortHandler: handleSort
      }
    },
    [EColumn.EXAM_STATUS]: {
      title: "Exam Status",
      isDisplayed: isExamsTab,
      centered: true
    },
    [EColumn.CSA]: {
      title: "C.S.A",
      isDisplayed: true,
      centered: true,
      dataTestId: "ageSexColumnHeader"
    },
    [EColumn.HSF]: {
      title: "HSF",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EColumnName.HIGH_SPEED_FURLONGS,
        sortOrder: getSortDirection(
          EColumnName.HIGH_SPEED_FURLONGS,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EColumn.DLW]: {
      title: "DLW",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EColumnName.LAST_WORKOUT_DATE,
        sortOrder: getSortDirection(
          EColumnName.LAST_WORKOUT_DATE,
          orderBy,
          order
        ),
        sortHandler: handleSort,
        isInverted: true
      }
    },
    [EColumn.DLR]: {
      title: "DLR",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EColumnName.LAST_RACE_DATE,
        sortOrder: getSortDirection(EColumnName.LAST_RACE_DATE, orderBy, order),
        sortHandler: handleSort,
        isInverted: true
      }
    },
    [EColumn.LAST_EXAM_DATE]: {
      title: "Last Exam",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EColumnName.LAST_EXAM_DATE,
        sortOrder: getSortDirection(EColumnName.LAST_EXAM_DATE, orderBy, order),
        sortHandler: handleSort
      }
    },
    [EColumn.ASSIGNED_VET]: {
      title: "Assigned Vet",
      isDisplayed: shouldShowAssignedVetColumn
    },
    [EColumn.UNASSIGN]: {
      title: "Un-Assign",
      isDisplayed: isExamsTab,
      centered: true
    },
    [EColumn.EXAM]: {
      title: "Exam",
      isDisplayed: isExamsTab,
      centered: true
    },
    [EColumn.EXAM_TYPE]: {
      title: "Exam Type",
      isDisplayed: isPassedTab
    },
    [EColumn.FLAG_REQUEST]: {
      title: "Flag Request",
      isDisplayed: isRequestsTab || isExamsTab,
      sortableCell: {
        name: EColumnName.IS_FLAGGED,
        sortOrder: getSortDirection(EColumnName.IS_FLAGGED, orderBy, order),
        sortHandler: handleSort
      }
    },
    [EColumn.ACTIVE_VET_LISTS]: {
      title: "Vet Lists",
      isDisplayed: true,
      centered: true,
      sortableCell: {
        name: EColumnName.ACTIVE_VET_LISTS_COUNT,
        sortOrder: getSortDirection(
          EColumnName.ACTIVE_VET_LISTS_COUNT,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EColumn.RISK_FACTORS]: {
      title: "Risk Factors",
      isDisplayed: true,
      sortableCell: {
        name: EColumnName.RISK_FACTORS_COUNT,
        sortOrder: getSortDirection(
          EColumnName.RISK_FACTORS_COUNT,
          orderBy,
          order
        ),
        sortHandler: handleSort
      }
    },
    [EColumn.IMAGE_DIAGNOSTICS_REQUIRED]: {
      title: "Imaging/Diagnostics",
      isDisplayed: isRejectedTab,
      centered: true,
      dataTestId: "diagnosticsColumnHeader"
    },
    [EColumn.EXTENDER]: {
      title: " ",
      isDisplayed: true,
      lastTwoColumnsCell: isRequestsTab,
      lastOneColumnCell: isExamsTab
    }
  };

  return (
    <>
      {isRequestsTab && (
        <ActionColumnHeaders
          columns={REQUESTS_TAB_ACTION_COLUMNS}
          refElement={refElement}
        />
      )}
      {isExamsTab && (
        <ActionColumnHeaders
          columns={EXAMS_TAB_ACTION_COLUMNS}
          refElement={refElement}
        />
      )}

      <TableHeader
        tabClassName={columnsClassName}
        columns={columns}
        columnsContent={tableHeaderColumnsContent}
      />
    </>
  );
};

export default React.memo(WorkoutsTableHeader);
