import React, { useCallback } from "react";
import { shallowEqual, useDispatch } from "react-redux";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useAppSelector } from "hooks/redux/useAppSelector";
import {
  setSearchValue,
  setSelectedPreRaceFacility
} from "store/actions/preRaceScreeningPage";
import ErrorBoundary from "components/ErrorBoundary";
import AppSearch from "components/AppSearch/AppSearch";
import FacilitySelect from "components/FacilitySelect";
import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";
import PickerFilter from "./PickerFilter";

import useStyles from "./styles";

interface IProps {
  upcomingRaceEntriesQuery: racehorse360.IUpcomingRaceEntryFilter;
}

const PreRaceScreeningPageHeader: React.FC<
  IProps & WithClickBlockerProps
> = props => {
  const { upcomingRaceEntriesQuery, handleBlockableClick } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { searchValue, selectedFacility } = useAppSelector(
    state => ({
      searchValue: state.preRaceScreeningPage.searchValue,
      selectedFacility: state.preRaceScreeningPage.selectedFacility
    }),
    shallowEqual
  );
  const handleFacilityChange = useCallback(
    (facility: racehorse360.IFacility) => {
      dispatch(setSelectedPreRaceFacility(facility));
    },
    [dispatch]
  );

  const handleSearch = (value: string) => {
    handleBlockableClick(() => dispatch(setSearchValue(value)));
  };

  return (
    <div className={classes.pageHeader}>
      <div className={classes.primaryFilter}>
        <div className={classes.facility}>
          <ErrorBoundary>
            <FacilitySelect
              selectedFacility={selectedFacility}
              onChange={handleFacilityChange}
            />
          </ErrorBoundary>
        </div>

        <PickerFilter className={classes.pickerFilterMobile} />
      </div>

      <div className={classes.secondaryFilter}>
        <div className={classes.searchFilter}>
          <ErrorBoundary>
            <AppSearch
              className={classes.search}
              isForceSearch
              placeholder={"Search Horses or Trainers"}
              upcomingRaceEntriesRequestFilter={upcomingRaceEntriesQuery}
              value={searchValue}
              onSearch={handleSearch}
              clearSearch={Boolean(!searchValue)}
            />
          </ErrorBoundary>
        </div>

        <PickerFilter className={classes.pickerFilterDesktop} />
      </div>
    </div>
  );
};

export default withClickBlocker<IProps>(React.memo(PreRaceScreeningPageHeader));
