import React from "react";

import { useScrollBarWidth } from "hooks/useScrollBarWidth";

import useStyles from "./styles";

interface IProps {
  columns: string[];
  refElement: HTMLElement;
}

const ActionColumnHeaders = (props: IProps) => {
  const { columns, refElement } = props;

  const scrollBarWidth = useScrollBarWidth(refElement);
  const classes = useStyles({ scrollBarWidth });

  return (
    <div className={classes.tableHeaderMenu}>
      <div className={classes.tableHeaderMenuContent}>
        <div className={classes.tableHeaderMenuColumns}>
          {columns.map(column => (
            <div key={column} className={classes.tableHeaderMenuColumn}>
              {column}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ActionColumnHeaders);
