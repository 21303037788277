import React, { HTMLAttributes } from "react";
import differenceInDays from "date-fns/differenceInDays";
import parseISO from "date-fns/parseISO";

import { NOT_ACCESSIBLE } from "common/constants";
import TableCellWrapper from "components/VetExamsTable/TableCellWrapper";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  bold?: boolean;
  lastEventDate: string;
}

const DaysSinceEventCell = (props: IProps) => {
  const { lastEventDate, ...restProps } = props;
  const lastEventDateContent = lastEventDate
    ? differenceInDays(new Date(), parseISO(lastEventDate))
    : NOT_ACCESSIBLE;

  return (
    <TableCellWrapper {...restProps} centered fadedOut={!lastEventDate}>
      {lastEventDateContent}
    </TableCellWrapper>
  );
};

export default React.memo(DaysSinceEventCell);
