import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import { racehorse360 } from "@tsg/1st-grpc-web";

import Breakpoints from "common/breakpoints";
import { UNKNOWN } from "common/constants";
import routes from "common/routes";
import AppSearch from "components/AppSearch";
import CalBredSired from "components/Icons/CalBredSired";
import ITrainerHorseDetailsPageState from "interfaces/TrainerHorseDetailsPageState";
import { parseHorseGenderFull } from "utils/enum-parser";
import { getHorseAge } from "utils/horse";
import SelectHorseDialog from "../../SelectHorseDialog";

import useStyles from "./styles";

export interface Props {
  horse: racehorse360.IHorse;
}

const PageHeader = (props: Props) => {
  const { horse } = props;
  const classes = useStyles();

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const isOpenPreferredConditions = useSelector(
    (state: {
      trainerHorseDetailsPage: ITrainerHorseDetailsPageState;
    }): boolean => {
      return state.trainerHorseDetailsPage.preferredConditionsState;
    }
  );

  const history = useHistory();
  const theme = useTheme();
  const matchesDownSM = useMediaQuery(
    theme.breakpoints.down(Breakpoints.SM_600),
    {
      noSsr: true
    }
  );

  const handleRedirect = (horseId: string) => {
    history.push(routes.horseDetails.path.replace(":horseId", String(horseId)));
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const renderHorseName = () => {
    if (matchesDownSM) {
      return (
        <div className={classes.horseNameButton} onClick={handleDialogOpen}>
          <Typography className={classes.name}>{horse.name}</Typography>
          <PlayArrowIcon
            className={clsx(classes.arrowIcon, {
              [classes.arrowIconReversed]: openDialog
            })}
          />
        </div>
      );
    }

    return <Typography className={classes.name}>{horse.name}</Typography>;
  };

  const renderStateBredIcon = () => {
    switch (horse?.stateEligibleCode) {
      case racehorse360.HorseStateEligibility
        .HORSE_STATE_ELIGIBILITY_CALIFORNIA:
        return <CalBredSired className={classes.cbsIcon} />;
      case racehorse360.HorseStateEligibility.HORSE_STATE_ELIGIBILITY_FLORIDA:
        // This icon needs to be replaced with Florida icon instead of California icon. Waiting for icon design
        return <CalBredSired className={classes.cbsIcon} />;
    }
  };

  return (
    <>
      {!isOpenPreferredConditions && (
        <Grid container className={classes.root} justifyContent="space-between">
          <Grid className={classes.leftPart} item>
            <Typography className={classes.currentlyViewing}>
              Currently Viewing
            </Typography>
            {renderHorseName()}
          </Grid>
          <Grid item className={classes.centralPart}>
            {!matchesDownSM && (
              <AppSearch
                placeholder={"Search Horses"}
                className={classes.appSearch}
                onRedirect={handleRedirect}
                isRedirect
              />
            )}
          </Grid>
          <Grid item className={classes.horseHeaderDetails}>
            <div className={classes.ageGender}>
              <Typography className={classes.age}>
                {getHorseAge(horse, true)}
              </Typography>
              <Typography className={classes.color}>
                {horse.colorDescription}{" "}
                {parseHorseGenderFull(horse?.gender, UNKNOWN)}
              </Typography>
            </div>
            {renderStateBredIcon()}
          </Grid>
        </Grid>
      )}

      {matchesDownSM && (
        <SelectHorseDialog
          open={openDialog}
          onClose={handleDialogClose}
          onRedirect={handleRedirect}
          horse={horse}
        />
      )}
    </>
  );
};

export default PageHeader;
