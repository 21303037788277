import React, { SyntheticEvent } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import routes from "common/routes";

import {
  withClickBlocker,
  WithClickBlockerProps
} from "components/BlockableClickContext";
import { setStoreActiveLinkCandidate } from "store/actions/sidebar";

import useStyles from "./styles";

interface IProps {
  horseId: string;
  children: React.ReactNode;
}

const HorseLink: React.FC<IProps & WithClickBlockerProps> = props => {
  const { horseId, children, handleBlockableLinkClick } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const horseDetailsUrl = routes.horseDetails.path.replace(
    ":horseId",
    String(horseId)
  );

  const handleLinkClick = (event: SyntheticEvent) => {
    const isClickBlocked = handleBlockableLinkClick(event);

    if (isClickBlocked) {
      event.preventDefault();
      dispatch(setStoreActiveLinkCandidate(horseDetailsUrl));
    }
  };

  return (
    <Link
      to={horseDetailsUrl}
      className={classes.horseNameLink}
      data-test-id={"horseName"}
      onClick={handleLinkClick}
    >
      {children}
    </Link>
  );
};

export default React.memo(withClickBlocker<IProps>(HorseLink));
