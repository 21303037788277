import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import useTheme from "@material-ui/core/styles/useTheme";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import TrainerIcon from "@material-ui/icons/Person";
import { InputBaseProps } from "@material-ui/core/InputBase";

import TextInputBase from "components/TextInputBase";
import SearchNoResults from "components/SearchNoResults";
import Loader from "components/Loader";
import HorseIcon from "components/Icons/Horse";
import { racehorse360 } from "@tsg/1st-grpc-web";
import { getHorseAge } from "utils/horse";
import { parseHorseGenderFull } from "utils/enum-parser";
import {
  getRequiredValue,
  getStringMatchQueryField
} from "utils/required-values-utils";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import { useRacehorse360Api } from "hooks/api";
import { SortOrder, SortOrderExtended } from "interfaces/SortOrder";
import { EMPTY_STRING, UNKNOWN } from "common/constants";
import { MuiTheme } from "theme";
import {
  getEntityName,
  getUniqueHorsesArrayFromEntities,
  TDesiredEntities
} from "./helper";

import useStyles, {
  INPUT_BOTTOM_MARGIN,
  INPUT_BOTTOM_MARGIN_XS,
  IStylesProps,
  ITEM_HEIGHT,
  ITEM_HEIGHT_DOWN_XS
} from "./styles";

const MIN_SIGNS_NUMBER_FOR_SEARCH = 3;
const MAX_SEARCH_RESULTS_COUNT = 99;

interface AppSearchClassNames {
  root?: string;
  itemsList?: string;
  formContent?: string;
  clearIcon?: string;
  searchIcon?: string;
  input?: string;
}

interface Props extends InputBaseProps {
  placeholder?: string;
  value?: string;
  isForceSearch?: boolean;
  onSearch?: (value: string, item?: TDesiredEntities) => void;
  onRedirect?: (horseId: string) => void;
  onForceSearch?: (
    value: string,
    item: racehorse360.ITrainer | racehorse360.IHorse | null
  ) => void;
  className?: string;
  classNames?: AppSearchClassNames;
  vetWorkoutRequestsFilter?: racehorse360.IWorkoutRequestFilter;
  upcomingRaceEntriesRequestFilter?: racehorse360.IUpcomingRaceEntryFilter;
  stallApplicationRequestFilter?: racehorse360.IStallApplicationFilter;
  shouldMakeHorseOrTrainerRequest?: boolean;
  clearSearch?: boolean;
  onClearSearch?: (value: boolean) => void;
  isRedirect?: boolean;
  showFullList?: boolean;
  showListOpened?: boolean;
  isCloseOnClickAway?: boolean;
  activeHorse?: racehorse360.IHorse | null;
  onCloseDialog?: () => void;
  showClearSearchButton?: boolean;
  onItemClick?: (item: TDesiredEntities) => void;
  withCheckMarkButton?: boolean;
}

const AppSearch = (props: Props) => {
  const {
    placeholder,
    className,
    isForceSearch,
    onSearch,
    classNames,
    isRedirect = false,
    onRedirect,
    showFullList = false,
    showListOpened = false,
    isCloseOnClickAway = true,
    activeHorse = null,
    onCloseDialog,
    showClearSearchButton = false,
    vetWorkoutRequestsFilter,
    upcomingRaceEntriesRequestFilter,
    stallApplicationRequestFilter,
    shouldMakeHorseOrTrainerRequest = false,
    clearSearch,
    onClearSearch,
    onItemClick,
    withCheckMarkButton = false,
    onFocus,
    onBlur
  } = props;

  let items: TDesiredEntities[] = [];

  const theme = useTheme<MuiTheme>();
  const formRef = useRef<HTMLFormElement>();
  const inputRef = useRef<HTMLInputElement>();
  const listContainerRef = useRef(null);
  const matchesDownXs = useMediaQuery(theme.breakpoints.down("xs"), {
    noSsr: true
  });

  const [stylesProps, setStylesProps] = useState<IStylesProps>();
  const classes = useStyles(stylesProps);

  const [focused, setFocused] = useState<boolean>(false);
  const [isListOpened, setIsListOpened] = useState<boolean>(showFullList);
  const [value, setValue] = useState(
    getRequiredValue(props.value, EMPTY_STRING)
  );
  const [searchValue, setSearchValue] = useState<string>(value);
  const { currentUser } = useLoggedInUser();
  const [activeItemIndex, setActiveItemIndex] = useState<number>(null);

  const itemHeight = matchesDownXs ? ITEM_HEIGHT_DOWN_XS : ITEM_HEIGHT;
  const isCloseIconFocused = [focused, value.length].some(Boolean);
  const stringMatchQueryContains = getStringMatchQueryField(
    "contains",
    searchValue
  );
  const stringMatchQuerySimilarOrContains = getStringMatchQueryField(
    "similarOrContains",
    searchValue
  );

  let facilityIds: string[];
  let shouldRequestHorses = false;
  let shouldRequestHorsesAndTrainers = false;
  let shouldRequestStallApplications = false;
  let shouldRequestWorkoutRequests = false;
  let shouldRequestRaceEntries = false;

  if (currentUser.isVeterinarian) {
    facilityIds = currentUser.vetFacilityIds;
  } else if (currentUser.isRacingOfficial) {
    facilityIds = currentUser.racingOfficialFacilityIds;
  }

  if (isListOpened) {
    shouldRequestHorsesAndTrainers = shouldMakeHorseOrTrainerRequest;
    shouldRequestStallApplications = Boolean(stallApplicationRequestFilter);
    shouldRequestWorkoutRequests = Boolean(vetWorkoutRequestsFilter);
    shouldRequestRaceEntries = Boolean(upcomingRaceEntriesRequestFilter);
    shouldRequestHorses = [
      !shouldMakeHorseOrTrainerRequest,
      !stallApplicationRequestFilter,
      !vetWorkoutRequestsFilter,
      !upcomingRaceEntriesRequestFilter
    ].every(Boolean);
  }

  const shouldStartSearch = [
    focused,
    searchValue.length >= MIN_SIGNS_NUMBER_FOR_SEARCH
  ].every(Boolean);
  const shouldShowCheckMarkButton = [
    shouldStartSearch,
    withCheckMarkButton
  ].every(Boolean);

  const {
    useListHorses,
    useListWorkoutRequests,
    usePullHorsesOrTrainersByName,
    useListStallApplications,
    useListUpcomingRaceEntries
  } = useRacehorse360Api();

  const {
    data: stallApplicationsData,
    isLoading: isListStallApplicationsLoading
  } = useListStallApplications(
    {
      query: {
        ...stallApplicationRequestFilter,
        contactFullName: stringMatchQueryContains,
        statuses: [
          racehorse360.StallApplicationStatus.STALL_APPLICATION_STATUS_APPLIED,
          racehorse360.StallApplicationStatus.STALL_APPLICATION_STATUS_COMPLETE,
          racehorse360.StallApplicationStatus
            .STALL_APPLICATION_STATUS_UNDER_REVIEW
        ]
      },
      getOptions: {
        select: ["contactFullName", "status"]
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: shouldRequestStallApplications
    }
  );

  const stallApplications = getRequiredValue(
    stallApplicationsData?.stallApplications,
    []
  );

  const {
    data: horseAndTrainersData,
    isLoading: areTrainersAndHorsesLoading,
    isFetching: areTrainersAndHorsesFetching
  } = usePullHorsesOrTrainersByName(
    {
      query: shouldRequestHorsesAndTrainers
        ? {
            horseOrTrainerName: stringMatchQueryContains,
            facilityIds
          }
        : null,
      pagingOptions: {
        maxResults: MAX_SEARCH_RESULTS_COUNT
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: shouldRequestHorsesAndTrainers
    }
  );

  const horseAndTrainers = getRequiredValue(
    horseAndTrainersData?.horsesOrTrainers?.map(item =>
      item.horse ? item.horse : item.trainer
    ),
    []
  );

  const {
    data: workoutRequestsData,
    isLoading: isWorkoutRequestsLoading,
    isFetching: isWorkoutRequestsFetching
  } = useListWorkoutRequests(
    {
      query: {
        ...vetWorkoutRequestsFilter,
        horseOrTrainerName: stringMatchQuerySimilarOrContains
      },
      pagingOptions: {
        maxResults: MAX_SEARCH_RESULTS_COUNT
      },
      getOptions: {
        select: [
          "horse.name",
          "horse.trainer.firstName",
          "horse.trainer.lastName",
          "horse.gender",
          "horse.birthday"
        ],
        orderBy: [
          `horseOrTrainerName ${SortOrderExtended.ASC_SIMILARITY}`,
          `horse.name ${SortOrder.ASC}`
        ]
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: shouldRequestWorkoutRequests
    }
  );

  const horseItemsFromWorkoutRequests = getUniqueHorsesArrayFromEntities(
    workoutRequestsData?.workoutRequests
  );

  const { data: raceEntriesData, isLoading: isRaceEntriesListLoading } =
    useListUpcomingRaceEntries(
      {
        query: {
          ...upcomingRaceEntriesRequestFilter,
          horseOrTrainerName: stringMatchQuerySimilarOrContains
        },
        pagingOptions: {
          maxResults: MAX_SEARCH_RESULTS_COUNT
        },
        getOptions: {
          select: [
            "horse.name",
            "horse.trainer.firstName",
            "horse.trainer.lastName",
            "horse.gender",
            "horse.birthday"
          ],
          orderBy: [
            `horseOrTrainerName ${SortOrderExtended.ASC_SIMILARITY}`,
            `horse.name ${SortOrder.ASC}`
          ]
        }
      },
      {
        enabled: shouldRequestRaceEntries
      }
    );

  const horseItemsFromRaceEntries = getUniqueHorsesArrayFromEntities(
    raceEntriesData?.upcomingRaceEntries
  );

  const {
    data: horsesListData,
    isLoading: isHorsesLoading,
    isFetching: isHorsesFetching
  } = useListHorses(
    {
      query: {
        name: stringMatchQuerySimilarOrContains,
        trainerIds: currentUser.isTrainer ? currentUser.trainerIds : null,
        facilityIds
      },
      pagingOptions: {
        maxResults: MAX_SEARCH_RESULTS_COUNT
      },
      getOptions: {
        select: [
          "name",
          "trainer.firstName",
          "trainer.lastName",
          "gender",
          "birthday"
        ],
        orderBy: searchValue.length
          ? [
              `name ${SortOrderExtended.ASC_SIMILARITY}`,
              `name ${SortOrder.ASC}`
            ]
          : [`name ${SortOrder.ASC}`]
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: shouldRequestHorses
    }
  );

  const horseItems = getRequiredValue(horsesListData?.horses, []);

  if (activeHorse && horseItems.length && !value) {
    const horseActiveIndex = horseItems.findIndex(
      horse => horse.id === activeHorse.id
    );

    horseActiveIndex !== -1 && horseItems.splice(horseActiveIndex, 1);
    horseItems.unshift(activeHorse);
  }

  const isLoading = [
    isWorkoutRequestsLoading,
    isWorkoutRequestsFetching,
    isHorsesLoading,
    isHorsesFetching,
    areTrainersAndHorsesLoading,
    areTrainersAndHorsesFetching,
    isListStallApplicationsLoading,
    isRaceEntriesListLoading
  ].some(Boolean);

  if (horseAndTrainers.length) {
    items = horseAndTrainers;
  } else if (stallApplications.length) {
    items = stallApplications;
  } else if (horseItems.length) {
    items = horseItems;
  } else if (horseItemsFromWorkoutRequests.length) {
    items = horseItemsFromWorkoutRequests;
  } else if (horseItemsFromRaceEntries.length) {
    items = horseItemsFromRaceEntries;
  }

  const selectItem = item => {
    const name = getEntityName(item);

    setValue(name);
    setIsListOpened(false);

    if (item.name?.length >= MIN_SIGNS_NUMBER_FOR_SEARCH && isRedirect) {
      if (activeHorse?.id === item.id) {
        //Close pop up when click on the green active horse row
        setIsListOpened(false);
        onCloseDialog();
      } else {
        onRedirect(item.id);
      }
    } else {
      checkSearch(name, true, item);
    }
  };

  const checkSearch = (
    value: string,
    submit?: boolean,
    item?: TDesiredEntities
  ) => {
    setSearchValue(value);

    if ((value !== searchValue && !isForceSearch) || submit) {
      onSearch && onSearch(value, item);
    }
  };

  const isItemInView = index => {
    const viewStart = listContainerRef.current.scrollTop;
    const viewEnd = viewStart + listContainerRef.current.offsetHeight;
    return (
      index * itemHeight + itemHeight * 1.5 < viewEnd &&
      index * itemHeight - itemHeight / 2 > viewStart
    );
  };

  const handleKeyDown = event => {
    if (isListOpened) {
      switch (event.key) {
        case "Up":
        case "ArrowUp": {
          event.preventDefault();
          const nextActive =
            activeItemIndex > 0 ? activeItemIndex - 1 : items.length - 1;
          if (!isItemInView(nextActive)) {
            listContainerRef.current.scrollTop =
              nextActive * itemHeight - itemHeight / 2;
          }
          setActiveItemIndex(nextActive);
          break;
        }
        case "Down":
        case "ArrowDown": {
          event.preventDefault();
          let nextActive = 0;
          if (activeItemIndex !== null) {
            nextActive =
              activeItemIndex < items.length - 1 ? activeItemIndex + 1 : 0;
          }
          if (!isItemInView(nextActive)) {
            listContainerRef.current.scrollTop =
              nextActive * itemHeight -
              listContainerRef.current.offsetHeight +
              itemHeight * 1.5;
          }
          setActiveItemIndex(nextActive);
          break;
        }
      }
    }
  };

  const handleValueChange = event => {
    const value = event.target.value;
    setValue(value);
    setActiveItemIndex(null);

    if (value.length >= MIN_SIGNS_NUMBER_FOR_SEARCH) {
      setIsListOpened(true);
      checkSearch(value);
    } else {
      setIsListOpened(false);
      checkSearch("");
    }
  };

  const handleClear = () => {
    setValue("");
    !showListOpened && setIsListOpened(false);
    checkSearch("", true);
  };

  const handleInputFocus = e => {
    setFocused(true);
    onFocus && onFocus(e);
  };

  const handleInputBlur = e => {
    setFocused(false);
    onBlur && onBlur(e);
  };

  const handleItemClick = item => () => {
    selectItem(item);
    onItemClick?.(item);
  };

  const handleClickAway = () => {
    isCloseOnClickAway && setIsListOpened(false);
  };

  const handleSubmit = () => {
    if (activeItemIndex !== null) {
      selectItem(items[activeItemIndex]);
    } else if (isForceSearch) {
      checkSearch(searchValue, true);
    }

    !showListOpened && setIsListOpened(false);
    setFocused(false);
  };

  useEffect(() => {
    clearSearch && handleClear();
    onClearSearch && onClearSearch(false);
  }, [clearSearch]);

  useEffect(() => {
    if (shouldStartSearch) {
      setIsListOpened(true);
    }
  }, [focused]);

  useEffect(() => {
    if (isListOpened) {
      const listContainerRelativeTop =
        formRef.current.offsetHeight +
        (matchesDownXs ? INPUT_BOTTOM_MARGIN_XS : INPUT_BOTTOM_MARGIN);
      const listContainerAbsoluteTop =
        formRef.current.getBoundingClientRect().bottom +
        (matchesDownXs ? INPUT_BOTTOM_MARGIN_XS : INPUT_BOTTOM_MARGIN);
      setStylesProps({
        listContainerRelativeTop,
        listContainerAbsoluteTop
      });
    }
  }, [isListOpened]);

  const renderHighLightedItem = (item: string) => {
    const format = /[ !"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~]/g;

    let safeSearchValue: string;
    let output: string | React.ReactElement = item;

    if (format.test(searchValue)) {
      safeSearchValue = searchValue.replace(format, "\\$&");
    } else {
      safeSearchValue = searchValue;
    }

    const regexPattern = `(.*)(${safeSearchValue})(.*)`;
    const result = new RegExp(regexPattern, "i").exec(item);

    if (result) {
      output = (
        <>
          {result[1]}
          <b>{result[2]}</b>
          {result[3]}
        </>
      );
    }

    return output;
  };

  const renderHorse = (item: racehorse360.IHorse) => {
    const isActive = [activeHorse?.id === item.id, !value].every(Boolean);
    const trainerName = `${getRequiredValue(
      item.trainer?.firstName,
      EMPTY_STRING
    )}
    ${getRequiredValue(item.trainer?.lastName, EMPTY_STRING)}`;

    return (
      <div className={clsx(classes.item, { [classes.itemActive]: isActive })}>
        <div className={classes.itemHorseName}>
          {isActive ? (
            <CheckIcon className={classes.checkIcon} />
          ) : (
            <HorseIcon
              className={clsx(classes.itemIcon, classes.itemNotActive)}
            />
          )}
          <div className={classes.itemHorseNameTitle}>
            {renderHighLightedItem(item.name)}
          </div>
        </div>

        <div
          className={clsx(classes.itemHorseDetails, {
            [classes.itemHorseDetailsTrainer]: !currentUser.isTrainer
          })}
        >
          {currentUser.isTrainer ? (
            <>
              <div>{`${getHorseAge(item)} years old`}</div>
              <div className={classes.itemHorseDetailsGender}>
                {parseHorseGenderFull(item.gender, UNKNOWN)}
              </div>
            </>
          ) : (
            <>
              <div>{trainerName}</div>
              <TrainerIcon
                className={clsx(
                  classes.itemIcon,
                  classes.itemHorseTrainerIcon,
                  {
                    [classes.itemActive]: isActive,
                    [classes.itemNotActive]: !isActive
                  }
                )}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const renderTrainer = (fullName: string) => (
    <div className={classes.item}>
      <div className={classes.itemTrainerName}>
        <TrainerIcon
          className={clsx(classes.itemIcon, classes.itemTrainerIcon)}
        />
        <div className={classes.itemTrainerNameTitle}>
          {renderHighLightedItem(fullName)}
        </div>
      </div>
    </div>
  );

  const renderList = () => {
    if (isLoading) {
      return <Loader className={classes.searchLoader} size={31} />;
    }

    return (
      <List
        component="ul"
        className={classes.dropDownSearchList}
        aria-label="drop-down search list"
        disablePadding
      >
        {renderListItems()}
      </List>
    );
  };

  const renderListItems = () => {
    if (items.length) {
      return items.map((item, index) => {
        const itemName = getEntityName(item);
        const renderItem =
          item instanceof racehorse360.Horse
            ? renderHorse(item)
            : renderTrainer(itemName);

        return (
          <ListItem
            className={clsx(
              classes.dropDownSearchListItem,
              index % 2 ? classes.rowOdd : classes.rowEven,
              {
                [classes.activeDropDownSearchListItem]:
                  index === activeItemIndex
              }
            )}
            key={index}
            component="li"
            divider
            onClick={handleItemClick(item)}
          >
            {renderItem}
          </ListItem>
        );
      });
    }

    return (
      <ListItem
        className={clsx(classes.dropDownSearchListItem, {
          [classes.noSearchResultButton]: showClearSearchButton && !items.length
        })}
        component="li"
      >
        <SearchNoResults
          classname={!showClearSearchButton && classes.searchNoResults}
          value={value}
          onClearSearch={handleClear}
          showClearSearchButton={showClearSearchButton}
        />
      </ListItem>
    );
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={clsx(classes.appSearch, className, classNames?.root)}>
        <form
          ref={formRef}
          action={"#"}
          className={clsx(classes.formContent, classNames?.formContent, {
            [classes.formFocused]: focused
          })}
          onSubmit={handleSubmit}
        >
          <SearchIcon
            className={clsx(classes.searchIcon, classNames?.searchIcon, {
              [classes.iconFocused]: focused
            })}
          />
          <TextInputBase
            className={classes.inputBase}
            classes={{
              input: clsx(classes.input, classNames?.input, {
                [classes.inputBaseFocused]: focused
              })
            }}
            placeholder={placeholder}
            type="search"
            value={value}
            onChange={handleValueChange}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onEnter={handleSubmit}
            onKeyDown={handleKeyDown}
            inputRef={inputRef}
          />
          {shouldShowCheckMarkButton && (
            <IconButton
              className={classes.checkMarkButton}
              onClick={handleSubmit}
            >
              <CheckIcon />
            </IconButton>
          )}
          {!!value.length && (
            <CloseIcon
              className={clsx(classes.closeIcon, classNames?.clearIcon, {
                [classes.closeIconFocused]: isCloseIconFocused
              })}
              onClick={handleClear}
            />
          )}
        </form>
        {isListOpened && (
          <div
            ref={listContainerRef}
            className={clsx(classes.items, classNames?.itemsList)}
          >
            {renderList()}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default React.memo(AppSearch);
