import React, { useEffect, useMemo, useRef } from "react";
import { shallowEqual, useDispatch } from "react-redux";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useDefaultTab } from "hooks/useDefaultTab";
import { useAppSelector } from "hooks/redux/useAppSelector";
import { setTableRows } from "store/actions/preRaceScreeningPage";
import { useRacehorse360Api } from "hooks/api";
import AppPage from "components/AppPage";
import AppPageHeader from "components/AppPageHeader";
import AppPageContent from "components/AppPageContent";
import Loader from "components/Loader";
import ErrorBoundary from "components/ErrorBoundary";
import { examsRowDataMapper } from "components/VetExamsTable/helper";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { getStringMatchQueryField } from "utils/required-values-utils";
import PreRaceScreeningPageHeader from "./PreRaceScreeningPageHeader";
import PreRaceScreeningSubHeader from "./PreRaceScreeningSubHeader";
import PreRacesTable from "./PreRacesTable";
import {
  commonRequestedFields,
  INFINITE_LIST_UPCOMING_RACE_ENTRIES_QUERY_KEY,
  possiblePreRaceScreeningRoutes
} from "./options";
import {
  checkCurrentRaceEntriesTableTab,
  getDateRangeFilter,
  getSortingRules
} from "./helper";

import useStyles from "./styles";

const PreRaceScreeningPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableBodyRef = useRef<HTMLDivElement>(null);

  const {
    selectedTab,
    selectedFacility,
    tableRows,
    showPassedEntries,
    orderBy,
    order,
    searchValue
  } = useAppSelector(
    state => ({
      selectedTab: state.preRaceScreeningPage.selectedTab,
      selectedFacility: state.preRaceScreeningPage.selectedFacility,
      tableRows: state.preRaceScreeningPage.tableRows,
      showPassedEntries: state.preRaceScreeningPage.showPassedEntries,
      orderBy: state.preRaceScreeningPage.orderBy,
      order: state.preRaceScreeningPage.order,
      searchValue: state.preRaceScreeningPage.searchValue
    }),
    shallowEqual
  );

  const { useInfiniteListUpcomingRaceEntries } = useRacehorse360Api();

  useDefaultTab(possiblePreRaceScreeningRoutes, null, selectedTab.path);

  const { isResultsTab, isRaceDayTab } = checkCurrentRaceEntriesTableTab(
    selectedTab.value
  );
  const shouldFilterPassedEntries =
    showPassedEntries && (isResultsTab || isRaceDayTab);

  const upcomingRaceEntriesQuery = useMemo(
    () => ({
      facilityIds: [selectedFacility?.id],
      statuses: selectedTab.entryStatuses,
      raceDate: getDateRangeFilter(isRaceDayTab, selectedFacility?.timezone),
      entryExamResult: shouldFilterPassedEntries
        ? racehorse360.HorseExamResult.HORSE_EXAM_RESULT_PASSED
        : null,
      horseOrTrainerName: getStringMatchQueryField("contains", searchValue)
    }),
    [
      selectedFacility?.id,
      selectedTab.entryStatuses,
      isRaceDayTab,
      selectedFacility?.timezone,
      shouldFilterPassedEntries,
      searchValue
    ]
  );

  const {
    data: upcomingRaceEntriesData,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage
  } = useInfiniteListUpcomingRaceEntries(
    INFINITE_LIST_UPCOMING_RACE_ENTRIES_QUERY_KEY,
    {
      query: upcomingRaceEntriesQuery,
      pagingOptions: {
        maxResults: selectedTab.itemsPerPage
      },
      getOptions: {
        select: [
          ...commonRequestedFields,
          ...selectedTab.additionalRequestedFields
        ],
        orderBy: getSortingRules(selectedTab, orderBy, order)
      }
    },
    {
      enabled: Boolean(selectedFacility?.id)
    }
  );

  useInfiniteScroll(
    tableBodyRef,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    true,
    true
  );

  const shouldShowMainLoader = [
    isLoading,
    !tableRows,
    isFetching && !isFetchingNextPage
  ].some(Boolean);

  const renderContent = () => {
    if (shouldShowMainLoader) return <Loader />;

    return (
      <ErrorBoundary>
        <PreRacesTable
          tableBodyRef={tableBodyRef}
          isFetchingNextPage={isFetchingNextPage}
        />
      </ErrorBoundary>
    );
  };

  useEffect(() => {
    if (upcomingRaceEntriesData) {
      const tableData = upcomingRaceEntriesData.pages?.flat();
      const updatedTableRows = tableData.map(item =>
        examsRowDataMapper(item, selectedTab.value)
      );

      tableData && dispatch(setTableRows(updatedTableRows));
    }
  }, [upcomingRaceEntriesData]);

  return (
    <AppPage>
      <AppPageHeader className={classes.appPageHeader}>
        <PreRaceScreeningPageHeader
          upcomingRaceEntriesQuery={upcomingRaceEntriesQuery}
        />
        <PreRaceScreeningSubHeader />
      </AppPageHeader>
      <AppPageContent>{renderContent()}</AppPageContent>
    </AppPage>
  );
};

export default React.memo(PreRaceScreeningPage);
