import React from "react";

import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import AppSearch from "components/AppSearch";

import { racehorse360 } from "@tsg/1st-grpc-web";
import ErrorBoundary from "components/ErrorBoundary";
import Breakpoints from "common/breakpoints";
import useStyles from "./styles";

export interface Props {
  open?: boolean;
  onClose?: () => void;
  onRedirect?: (horseId: string) => void;
  horse?: racehorse360.IHorse;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectHorseDialog = (props: Props) => {
  const { open, onClose, onRedirect, horse } = props;
  const classes = useStyles();

  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down(Breakpoints.XS_420), {
    noSsr: true
  });

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      className={classes.selectHorseDialog}
      open={open}
      TransitionComponent={Transition}
      style={matchesXS ? { top: "56px" } : { top: "64px" }}
    >
      <Grid container className={classes.header}>
        <Grid container className={classes.topBar}>
          <Grid container alignItems={"center"}>
            <Typography className={classes.topBarTitle}>
              Select Horse
            </Typography>
            <PlayArrowIcon
              className={classes.arrowIcon}
              onClick={handleClose}
            />
          </Grid>
          <Button className={classes.cancelButton} onClick={handleClose}>
            CANCEL
          </Button>
        </Grid>
      </Grid>

      <ErrorBoundary>
        <AppSearch
          placeholder={"Search Horses"}
          classNames={{
            itemsList: classes.itemsList,
            formContent: classes.formContent
          }}
          onRedirect={onRedirect}
          isRedirect
          showFullList
          showListOpened
          isCloseOnClickAway={false}
          activeHorse={horse}
          onCloseDialog={onClose}
          showClearSearchButton
        />
      </ErrorBoundary>
    </Dialog>
  );
};

export default SelectHorseDialog;
