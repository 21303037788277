import { racehorse360 } from "@tsg/1st-grpc-web";

export type TDesiredEntities =
  | racehorse360.IHorse
  | racehorse360.ITrainer
  | racehorse360.IStallApplication;

export const getEntityName = (item: TDesiredEntities): string => {
  let output;

  if (item instanceof racehorse360.Trainer) {
    output = `${item.firstName} ${item.lastName}`;
  } else if (item instanceof racehorse360.Horse) {
    output = item.name;
  } else if (item instanceof racehorse360.StallApplication) {
    output = item.contactFullName;
  }

  return output;
};

export const getUniqueHorsesArrayFromEntities = (
  entities: (racehorse360.IWorkoutRequest | racehorse360.IUpcomingRaceEntry)[]
): racehorse360.IHorse[] => {
  if (!entities?.length) return [];

  return entities
    .map(wr => wr.horse)
    .reduce<racehorse360.IHorse[]>(
      (acc: racehorse360.IHorse[], cur: racehorse360.IHorse) =>
        acc.some(h => h.id === cur.id) ? acc : [...acc, cur],
      []
    );
};
