import React, { useMemo, useState } from "react";
import { useRouteMatch } from "react-router";
import { Link, useLocation } from "react-router-dom";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

import { racehorse360 } from "@tsg/1st-grpc-web";

import AppPage from "components/AppPage";
import AppPageContent from "components/AppPageContent";
import AppPageHeader from "components/AppPageHeader";
import AppSearch from "components/AppSearch";
import Loader from "components/Loader";
import NoContent from "components/NoContent";
import routes from "common/routes";
import StallApplicantsTable from "./StallApplicantsTable";
import {
  checkCurrentApplicantsTab,
  EApplicantsTableTab,
  getStallApplicationStatusByTab,
  getTabByStallApplicationStatus,
  tableColumnsSets
} from "./helpers";
import { useRacehorse360Api } from "hooks/api";
import { ILocationState } from "../StallApplicationFormPage/constants";
import useStyles from "./styles";

export const LIST_STALL_APPLICATION_REQUEST_KEY = "listStallApplications";
export const APPLICATION_FORM_SUMMARY_REQUEST_KEY = "applicationFormSummary";
const SEARCH_BOX_PLACEHOLDER = "Search Trainers";

const StallApplicationFormViewPage = () => {
  const classes = useStyles();

  const location = useLocation<ILocationState>();
  const {
    params: { stallApplicationFormId }
  } = useRouteMatch<{ stallApplicationFormId: string }>(
    routes.stallApplicationFormView.path
  );

  const [searchValue, setSearchValue] = useState<string>(null);
  const [clearSearch, setClearSearch] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    EApplicantsTableTab.APPLICANTS
  );
  const [stallApplicationStatusFilter, setStallApplicationStatusFilter] =
    useState(
      racehorse360.StallApplicationStatus.STALL_APPLICATION_STATUS_APPLIED
    );

  const { isReviewedTab } = checkCurrentApplicantsTab(selectedTab);
  const contactFullName = searchValue ? { contains: searchValue } : null;

  const {
    useGetStallApplicationForm,
    useListStallApplications,
    usePullStallApplicationFormSummary
  } = useRacehorse360Api();

  const {
    data: stallApplicationForm,
    isLoading: isLoadingStallApplicationForm
  } = useGetStallApplicationForm({
    id: stallApplicationFormId,
    getOptions: {
      select: [
        "title",
        "deadlineDate",
        "facilityOptions",
        "facilityOptions:name"
      ]
    }
  });

  const {
    data: applicantsCounts,
    isLoading: areApplicantsCountsLoading,
    isFetching: areApplicantsCountsFetching
  } = usePullStallApplicationFormSummary(APPLICATION_FORM_SUMMARY_REQUEST_KEY, {
    stallApplicationFormId,
    contactFullName
  });

  const {
    applicantsCount = 0,
    reviewedCount = 0,
    inProgressCount = 0
  } = { ...applicantsCounts };

  const stallApplicationsQueryFilter: racehorse360.IStallApplicationFilter =
    useMemo(
      () => ({
        stallApplicationFormId,
        statuses: [stallApplicationStatusFilter],
        contactFullName
      }),
      [stallApplicationFormId, stallApplicationStatusFilter, contactFullName]
    );

  const {
    data,
    isLoading: isListStallApplicationsLoading,
    isFetching: isListStallApplicationsFetching
  } = useListStallApplications(
    LIST_STALL_APPLICATION_REQUEST_KEY,
    {
      query: stallApplicationsQueryFilter,
      getOptions: {
        select: [
          "arrivalDate",
          "sleepingRoomsNumber",
          "contactFullName",
          "stableEntriesCount",
          "firstChoiceFacility.code",
          "secondChoiceFacility.code",
          "stableEntriesApprovedCount",
          "stableEntriesDeniedCount",
          "stableEntriesAwaitingCount",
          "sleepingRoomsNumberFirstChoice",
          "sleepingRoomsNumberSecondChoice",
          "stallsNumberFirstChoice",
          "stallsNumberSecondChoice"
        ]
      }
    },
    {
      onError: error => console.error(error)
    }
  );

  const getTableColumns = () => {
    return tableColumnsSets[selectedTab];
  };

  const getPathname = () => {
    return routes.stallApplicationTrainerForm.path.replace(
      ":stallApplicationFormId",
      stallApplicationFormId
    );
  };

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
    setStallApplicationStatusFilter(getStallApplicationStatusByTab(value));
  };

  const handleSearchStallApplications = (
    stallApplication: racehorse360.IStallApplication
  ) => {
    const status = stallApplication?.status;

    setSearchValue(stallApplication?.contactFullName);
    setStallApplicationStatusFilter(status);
    setSelectedTab(getTabByStallApplicationStatus(status));
  };

  const handleClearSearch = () => {
    setSearchValue(null);
    setClearSearch(true);
  };

  const handleSearch = (
    value: string,
    stallApplication: racehorse360.IStallApplication
  ) => {
    if (stallApplication) {
      handleSearchStallApplications(stallApplication);
    } else {
      setSearchValue(value);
    }
  };

  const renderTabContent = () => {
    return data?.stallApplications.length ? (
      <StallApplicantsTable
        stallApplicationForm={stallApplicationForm}
        stallApplications={data.stallApplications}
        tableColumns={getTableColumns()}
        isReviewedTab={isReviewedTab}
      />
    ) : (
      <NoContent>No Applicants</NoContent>
    );
  };

  const isTableDataLoading = [
    isListStallApplicationsLoading,
    isListStallApplicationsFetching,
    areApplicantsCountsLoading,
    areApplicantsCountsFetching
  ].some(Boolean);

  return (
    <AppPage>
      <AppPageHeader className={classes.appPageHeader}>
        <div className={classes.formName}>
          {isLoadingStallApplicationForm ? (
            <Loader />
          ) : (
            <h3>{stallApplicationForm.title}</h3>
          )}
        </div>
        <div className={classes.menu}>
          <AppSearch
            className={classes.search}
            placeholder={SEARCH_BOX_PLACEHOLDER}
            onItemClick={handleSearchStallApplications}
            onSearch={handleSearch}
            clearSearch={clearSearch}
            onClearSearch={handleClearSearch}
            stallApplicationRequestFilter={stallApplicationsQueryFilter}
            isForceSearch
          />
          <Link
            className={classes.link}
            to={{
              pathname: getPathname(),
              state: { facilityId: location.state.facilityId }
            }}
          >
            Manual Entry
          </Link>
        </div>
      </AppPageHeader>
      <AppPageContent className={classes.pageContent}>
        <Tabs
          value={selectedTab}
          className={classes.tabs}
          onChange={handleTabChange}
          TabIndicatorProps={{
            title: "indicator",
            className: classes.activeTab
          }}
        >
          <Tab
            label={`Applicants (${applicantsCount})`}
            value={EApplicantsTableTab.APPLICANTS}
          />
          <Tab
            label={`Reviewed (${reviewedCount})`}
            value={EApplicantsTableTab.REVIEWED}
          />
          <Tab
            label={`In-progress (${inProgressCount})`}
            value={EApplicantsTableTab.IN_PROGRESS}
          />
        </Tabs>
        <div className={classes.tabContent}>
          {isTableDataLoading && <Loader overlay />}
          {!isTableDataLoading && renderTabContent()}
        </div>
      </AppPageContent>
    </AppPage>
  );
};

export default StallApplicationFormViewPage;
