import { racehorse360 } from "@tsg/1st-grpc-web";
import {
  SortOrder,
  SortOrderExtended,
  SortOrderType
} from "interfaces/SortOrder";
import { checkSortingType } from "utils/sort-utils";
import { getPersonFullName } from "utils/person";
import { ISelectedHorsesWithRiskFactors } from "./ConfirmSelectionsDialog/ConfirmSelectionsDialog";

export enum WorkoutsTableTab {
  REQUESTS = "requests",
  EXAMS = "exams",
  PASSED = "passed",
  FAILED = "failed",
  REJECTED = "rejected"
}

export interface WorkoutsTabsStatuses {
  isRequestsTab: boolean;
  isExamsTab: boolean;
  isPassedTab: boolean;
  isFailedTab: boolean;
  isRejectedTab: boolean;
}

export const checkCurrentWorkoutsTableTab = (
  tab: WorkoutsTableTab
): WorkoutsTabsStatuses => {
  return {
    isRequestsTab: tab === WorkoutsTableTab.REQUESTS,
    isExamsTab: tab === WorkoutsTableTab.EXAMS,
    isPassedTab: tab === WorkoutsTableTab.PASSED,
    isFailedTab: tab === WorkoutsTableTab.FAILED,
    isRejectedTab: tab === WorkoutsTableTab.REJECTED
  };
};

export const getUniqueCheckedHorses = (
  workoutsRequests: racehorse360.IWorkoutRequest[]
): ISelectedHorsesWithRiskFactors[] => {
  const uniqueCheckedHorsesIds = new Set();

  return workoutsRequests
    .filter(workoutRequest => {
      const shouldPass =
        workoutRequest.horse.riskFactorsCount &&
        !uniqueCheckedHorsesIds.has(workoutRequest.horse.id);

      if (shouldPass) {
        uniqueCheckedHorsesIds.add(workoutRequest.horse.id);

        return true;
      }

      return false;
    })
    .map(workoutRequest => ({
      horseId: workoutRequest.horse.id,
      horseName: workoutRequest.horse.name,
      riskFactorsCount: workoutRequest.horse.riskFactorsCount,
      trainerName: getPersonFullName(
        {
          firstName: workoutRequest.horse.trainer?.firstName,
          lastName: workoutRequest.horse.trainer?.lastName
        },
        "%f %L"
      )
    }));
};

export const getOrderRules = (
  selectedTab: WorkoutsTableTab,
  orderByOptions: string[],
  order: SortOrderType
): string[] => {
  const { isExamsTab } = checkCurrentWorkoutsTableTab(selectedTab);
  const defaultRules = [`horse.name ${SortOrder.ASC}`, `date ${SortOrder.ASC}`];
  const { isOneOfAsc } = checkSortingType(order);
  const isSortingNullSensitive = [
    "horse.healthWatchStatus",
    "horse.barn.name",
    "horse.lastExamDate"
  ].some(sortType => orderByOptions.includes(sortType));

  let originalRules: string[] = [];

  if (isSortingNullSensitive) {
    originalRules = orderByOptions.map(opt => {
      return `${opt} ${
        isOneOfAsc
          ? SortOrderExtended.ASC_NULLS_LAST
          : SortOrderExtended.DESC_NULLS_LAST
      }`;
    });
  } else {
    originalRules = orderByOptions.map(opt => `${opt} ${order}`);
  }

  let additionalRules: string[] = [];

  if (orderByOptions.includes("isFlagged")) {
    additionalRules = [`flag.priority ${order}`];
  } else if (isExamsTab && orderByOptions.includes("horse.trainer.lastName")) {
    additionalRules = [
      `isFlagged ${SortOrder.DESC}`,
      `flag.priority ${SortOrder.DESC}`,
      `horse.healthWatchStatus ${SortOrderExtended.DESC_NULLS_LAST}`
    ];
  }

  return [...originalRules, ...additionalRules, ...defaultRules];
};

export const getOrderByOptions = (
  options: string[] = [],
  orderBy: string
): string[] => {
  if (orderBy === "horse.name" || orderBy === "horse.trainer.lastName") {
    options = options.filter(item => item === orderBy);
  }

  return options;
};

export const filterVisibleCheckedWorkoutIds = (
  workoutRequests: racehorse360.IWorkoutRequest[],
  allCheckedIds: string[]
): string[] => {
  if (!workoutRequests?.length || !allCheckedIds?.length) {
    return [];
  }

  return workoutRequests
    .filter(item => allCheckedIds.includes(item.id))
    .map(item => item.id);
};
