import { makeStyles, Theme } from "@material-ui/core/styles";

interface StylesProps {
  scrollBarWidth: number;
}

const ACTION_COLUMNS_HEADER_RIGHT_INDENT = 19;

export default makeStyles<Theme, StylesProps | null>({
  tableHeaderMenu: {
    position: "absolute",
    top: 0,
    left: 0,
    right: ({ scrollBarWidth }) =>
      ACTION_COLUMNS_HEADER_RIGHT_INDENT + scrollBarWidth,
    height: 0,
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 9
  },
  tableHeaderMenuContent: {
    padding: "4px 4px 0",
    margin: "0 4px",
    height: 44,
    backgroundColor: "#fff",
    overflow: "hidden"
  },
  tableHeaderMenuColumns: {
    display: "flex",
    boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.15)",
    alignItems: "center",
    height: 40,
    padding: "0 4px 4px"
  },
  tableHeaderMenuColumn: {
    width: 52,
    textAlign: "center"
  }
});
