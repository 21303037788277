import IPreRaceScreeningPageState from "interfaces/PreRaceScreeningPageState";
import { SortOrder } from "interfaces/SortOrder";
import { preRacesTableTabs } from "pages/PreRaceScreeningPage/options";
import { EPreRaceTableColumnSorting } from "pages/PreRaceScreeningPage/types";
import {
  FILTER_TABLE_ROW,
  SET_ORDER_BY,
  SET_SEARCH_VALUE,
  SET_SELECTED_PRE_RACE_FACILITY,
  SET_SELECTED_PRE_RACE_TABLE_TAB,
  SET_SHOW_PASSED_ENTRIES_CHECKBOX_STATE,
  SET_SORT_ORDER,
  SET_TABLE_ROWS,
  TPreRaceScreeningAction
} from "store/actions/preRaceScreeningPage";

const initialState: IPreRaceScreeningPageState = {
  selectedTab: preRacesTableTabs[0],
  searchValue: "",
  order: SortOrder.ASC,
  orderBy: EPreRaceTableColumnSorting.RACE_DATE,
  selectedFacility: null,
  showPassedEntries: false,
  tableRows: null
};

export default (
  state = initialState,
  action: TPreRaceScreeningAction
): IPreRaceScreeningPageState => {
  switch (action.type) {
    case SET_SELECTED_PRE_RACE_TABLE_TAB: {
      return {
        ...state,
        selectedTab: action.selectedTab
      };
    }
    case SET_SELECTED_PRE_RACE_FACILITY: {
      return {
        ...state,
        selectedFacility: action.selectedFacility
      };
    }
    case SET_SHOW_PASSED_ENTRIES_CHECKBOX_STATE: {
      return {
        ...state,
        showPassedEntries: action.isChecked
      };
    }
    case SET_TABLE_ROWS: {
      return {
        ...state,
        tableRows: action.rows
      };
    }
    case FILTER_TABLE_ROW: {
      return {
        ...state,
        tableRows: state.tableRows.filter(row => row.eventId !== action.rowId)
      };
    }
    case SET_SORT_ORDER: {
      return {
        ...state,
        order: action.order
      };
    }
    case SET_ORDER_BY: {
      return {
        ...state,
        orderBy: action.orderBy
      };
    }
    case SET_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: action.searchValue
      };
    }
    default:
      return state;
  }
};
