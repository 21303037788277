import React, { ChangeEvent, useEffect, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import clsx from "clsx";

import { racehorse360 } from "@tsg/1st-grpc-web";

import {
  IExamsTableRowData,
  ITableColumnsContent
} from "components/VetExamsTable/types";
import ExamStatusCell from "components/VetExamsTable/TableRow/RowContent/ExamStatusCell/ExamStatusCell";
import Row from "components/VetExamsTable/TableRow/Row";
import RowContent from "components/VetExamsTable/TableRow/RowContent/RowContent";
import ExamNote from "components/VetExamsTable/ExamNote";
import TrainerCell from "components/VetExamsTable/TableRow/RowContent/TrainerCell/TrainerCell";
import DateCell from "components/VetExamsTable/TableRow/RowContent/DateCell";
import ExamCell from "components/VetExamsTable/TableRow/RowContent/ExamCell/ExamCell";
import TableCellWrapper from "components/VetExamsTable/TableCellWrapper";
import FlagPriority from "components/VetExamsTable/TableRow/RowContent/FlagCell/FlagPriority";
import { getUpcomingRaceEntryFlagPriority } from "components/VetExamsTable/TableRow/RowContent/FlagCell/helper";
import { useExamLockerContext } from "components/ExamLockerContext/ExamLockerContext";
import { EFlagPriority } from "components/VetExamsTable/helper";
import { filterTableRow } from "store/actions/preRaceScreeningPage";
import { useAppSelector } from "hooks/redux/useAppSelector";
import { getDateInTimeZone } from "utils/date-utils";
import { useRacehorse360Api } from "hooks/api";
import PreRaceRowActionCells from "./PreRaceRowActionCells";
import {
  checkCurrentRaceEntriesTableTab,
  getPreRaceExamTableStatus
} from "../../helper";
import { EPreRaceTableColumn } from "../../types";

import useStyles from "./styles";

interface IProps {
  index: number;
  columnsClassName: string;
  rowData: IExamsTableRowData;
  columns: string[];
  onOpenExamForm: (
    examId: string,
    status: racehorse360.HorseExamStatus
  ) => void;
}

const PreRaceRow = (props: IProps) => {
  const { index, rowData, columnsClassName, columns, onOpenExamForm } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const { pullLocker, isLocked } = useExamLockerContext();

  const [currentRowData, setCurrentRowData] =
    useState<IExamsTableRowData>(rowData);
  const [isExamChecked, setIsExamChecked] = useState(false);
  const [isPassChecked, setIsPassChecked] = useState(false);

  const { selectedTab } = useAppSelector(
    state => ({
      selectedTab: state.preRaceScreeningPage.selectedTab,
      searchValue: state.preRaceScreeningPage.searchValue
    }),
    shallowEqual
  );

  const {
    useSetUpcomingRaceEntryFlag,
    useResetUpcomingRaceEntryFlag,
    useSetUpcomingRaceEntryExamNote,
    useResetUpcomingRaceEntryExamNote,
    useRequireUpcomingRaceEntryRaceDayExam,
    useRevertQuickPassUpcomingRaceEntryExam
  } = useRacehorse360Api();

  const { mutateAsync: setRaceEntryFlag } = useSetUpcomingRaceEntryFlag();
  const { mutateAsync: resetRaceEntryFlag } = useResetUpcomingRaceEntryFlag();
  const { mutateAsync: createRaceEntryRaceDayExam } =
    useRequireUpcomingRaceEntryRaceDayExam();
  const {
    mutateAsync: revertQuickPassRaceEntryExam,
    isPending: isQuickPassRaceEntryExamReverting
  } = useRevertQuickPassUpcomingRaceEntryExam();
  const { mutateAsync: setEntryExamNote, isPending: isEntryExamNoteSetting } =
    useSetUpcomingRaceEntryExamNote();
  const {
    mutateAsync: resetEntryExamNote,
    isPending: isEntryExamNoteResetting
  } = useResetUpcomingRaceEntryExamNote();

  const {
    eventId: preRaceId,
    eventDate: preRaceDate,
    eventNumber: preRaceNumber,
    eventPosition: preRacePosition,
    eventFlagPriority: preRaceFlagPriority,
    eventFacility,
    examId,
    examType,
    examStatus,
    examResult,
    horse
  } = currentRowData;

  const { isEntriesTab, isEntryExamsTab, isResultsTab, isRaceDayTab } =
    checkCurrentRaceEntriesTableTab(selectedTab.value);

  const shouldShowFlagPriorityIndicator = [
    preRaceFlagPriority,
    !isResultsTab
  ].every(Boolean);
  const shouldShowFlagOnTrack = [
    isEntriesTab,
    horse.hasActualFlagOnTrack
  ].every(Boolean);
  const shouldShowLastExamNote = [
    horse.lastCompletedExamWithNote,
    isEntriesTab
  ].every(Boolean);
  const shouldShowRevertButton = [
    isResultsTab,
    rowData.examType === racehorse360.HorseExamType.HORSE_EXAM_TYPE_QUICK_PASS,
    getDateInTimeZone(new Date(), eventFacility?.timezone) <
      new Date(rowData.eventDate)
  ].every(Boolean);
  const isEntryExamLoading = [
    isEntryExamNoteSetting,
    isEntryExamNoteResetting
  ].some(Boolean);

  const handleExamButtonClick = async () => {
    const response = await pullLocker();

    if (response.data.isLocked) return;

    if (examId) {
      onOpenExamForm(examId, examStatus);

      return;
    }

    const createdRaceEntry = await createRaceEntryRaceDayExam({
      id: preRaceId,
      getOptions: {
        select: ["raceDayExam.id", "raceDayExam.status"]
      }
    });

    if (!createdRaceEntry) return;

    const { id, status } = createdRaceEntry.raceDayExam;

    onOpenExamForm(id, status);
  };

  const handleUndoQuickPassConfirm = async () => {
    const revertedRaceEntry = await revertQuickPassRaceEntryExam({
      id: preRaceId
    });

    revertedRaceEntry && dispatch(filterTableRow(revertedRaceEntry.id));
  };

  const handleSetExamNote = async (note: string) => {
    const updatedRaceEntry = await setEntryExamNote({
      id: preRaceId,
      body: note,
      getOptions: {
        select: [
          "entryExamNote.body",
          "entryExamNote.notedBy",
          "entryExamNote.notedOn"
        ]
      }
    });

    setCurrentRowData({
      ...currentRowData,
      examNote: updatedRaceEntry.entryExamNote
    });
  };

  const handleResetExamNote = async () => {
    await resetEntryExamNote({ id: preRaceId });

    setCurrentRowData({
      ...currentRowData,
      examNote: null
    });
  };

  const handleChangePriority = async (event: ChangeEvent<HTMLInputElement>) => {
    const flagPriority = getUpcomingRaceEntryFlagPriority(
      event.currentTarget.value as EFlagPriority
    );
    let updatedRaceEntry;

    if (
      flagPriority ===
      racehorse360.UpcomingRaceEntryFlagPriority
        .UPCOMING_RACE_ENTRY_FLAG_PRIORITY_INVALID
    ) {
      updatedRaceEntry = await resetRaceEntryFlag({ id: preRaceId });
    } else {
      updatedRaceEntry = await setRaceEntryFlag({
        id: preRaceId,
        flagPriority,
        getOptions: {
          select: ["flagPriority"]
        }
      });
    }

    setCurrentRowData({
      ...currentRowData,
      eventFlagPriority: updatedRaceEntry.flagPriority
    });
  };

  const examNoteContent = !isResultsTab && (
    <ExamNote
      index={index}
      rowData={currentRowData}
      isLoading={isEntryExamLoading}
      readOnly={isEntryExamsTab || isRaceDayTab}
      onSaveComment={handleSetExamNote}
      onDeleteComment={handleResetExamNote}
      noteLabel={"Entry Exam Note"}
    />
  );

  const actionCells = isEntriesTab && (
    <PreRaceRowActionCells
      index={index}
      entryId={preRaceId}
      isExamChecked={isExamChecked}
      isPassChecked={isPassChecked}
      onSetIsExamChecked={setIsExamChecked}
      onSetIsPassChecked={setIsPassChecked}
    />
  );

  const columnsContent: ITableColumnsContent = {
    [EPreRaceTableColumn.TRAINER]: (
      <TrainerCell
        key={`${preRaceId}-trainer`}
        bold={isEntriesTab}
        trainerInfo={horse.trainer}
      />
    ),
    [EPreRaceTableColumn.RACE_DATE]: (
      <DateCell
        key={`${preRaceId}-race-date`}
        date={preRaceDate}
        bold={isEntriesTab}
      />
    ),
    [EPreRaceTableColumn.RACE_NUMBER]: (
      <TableCellWrapper
        key={`${preRaceId}-race-number`}
        bold={isEntriesTab}
        centered
      >
        {preRaceNumber}
      </TableCellWrapper>
    ),
    [EPreRaceTableColumn.RACE_POSITION]: (
      <TableCellWrapper
        key={`${preRaceId}-race-position`}
        bold={isEntriesTab}
        centered
      >
        {preRacePosition}
      </TableCellWrapper>
    ),
    [EPreRaceTableColumn.EXAM]: (
      <ExamCell
        key={`${preRaceId}-exam`}
        shouldShowButton={true}
        disabledButton={isLocked}
        onExamButtonClick={handleExamButtonClick}
      />
    ),
    [EPreRaceTableColumn.ENTRY_REVIEW]: (
      <ExamStatusCell
        key={`${preRaceId}-exam-type`}
        status={getPreRaceExamTableStatus(examType, examResult)}
        horseName={horse.name}
        eventName={"Race"}
        eventTabName={"Entries"}
        eventDate={preRaceDate}
        shouldShowRevertButton={shouldShowRevertButton}
        onConfirm={handleUndoQuickPassConfirm}
        isLoading={isQuickPassRaceEntryExamReverting}
      />
    ),
    [EPreRaceTableColumn.WATCH_LIST]: (
      <TableCellWrapper key={`${preRaceId}-watch-list`}>
        <FlagPriority
          priority={preRaceFlagPriority}
          onChangePriority={handleChangePriority}
          defaultButtonTitle={"Watch"}
          selectedButtonTitle={"Watching"}
        />
      </TableCellWrapper>
    )
  };

  useEffect(() => {
    setCurrentRowData(rowData);
  }, [rowData]);

  return (
    <>
      <Row
        index={index}
        rowContainerClassName={clsx({
          [classes.rowExamChecked]: isExamChecked,
          [classes.rowPassChecked]: isPassChecked
        })}
        rowContentClassName={columnsClassName}
        rowData={currentRowData}
        examNoteContent={examNoteContent}
        actionCellsContent={actionCells}
        shouldShowFlagPriorityIndicator={shouldShowFlagPriorityIndicator}
        shouldShowFlagOnTrack={shouldShowFlagOnTrack}
        shouldShowLastExamNote={shouldShowLastExamNote}
        shouldShowExamsTablePPs={isEntriesTab}
      >
        <RowContent
          rowData={currentRowData}
          columns={columns}
          bold={isEntriesTab}
          customColumnsContent={columnsContent}
          extenderFitsTwoColumns={isEntriesTab}
        />
      </Row>
    </>
  );
};

export default React.memo(PreRaceRow);
