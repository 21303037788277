import React, { useCallback, useReducer } from "react";
import clsx from "clsx";

import Button from "@material-ui/core/Button";

import { racehorse360 } from "@tsg/1st-grpc-web";

import { useRacehorse360Api } from "hooks/api";
import ButtonsGroup from "components/ButtonsGroup";
import CommentField from "components/CommentField";
import Loader from "components/Loader";
import AppSearch from "components/AppSearch/AppSearch";
import { useSnackbar } from "components/SnackbarContext/SnackbarContext";
import {
  ETouchMoveListenerAction,
  setupTouchMoveListener
} from "pages/TrackOpticsPage/helpers";
import {
  changeWorkoutAnalysisFormValueReducer,
  initialState,
  mapGroupButtonsOptions,
  TRACK_OPTICS_INJURY_ENTRY_NONE_ID,
  TRACK_OPTICS_INJURY_ENTRY_UNUSABLE_ID,
  WorkoutAnalysisFormField
} from "./helpers";
import InjuryFields from "./InjuryFields";

import useStyles from "./styles";

interface IProps {
  trackOpticsVideo: racehorse360.ITrackOpticsVideo;
  updateVideoList: () => void;
}

const SEARCH_BOX_PLACEHOLDER = "Search or Enter Horse Name";

const WorkoutAnalysisForm = (props: IProps) => {
  const { trackOpticsVideo, updateVideoList } = props;

  const classes = useStyles();
  const { showSuccessSnack } = useSnackbar();

  const [workoutAnalysisFormState, dispatch] = useReducer(
    changeWorkoutAnalysisFormValueReducer,
    initialState
  );

  const { usePullTrackOpticsVideoConditions, useSubmitTrackOpticsVideo } =
    useRacehorse360Api();

  const { data, isLoading } = usePullTrackOpticsVideoConditions({});

  const { mutateAsync: submitForm } = useSubmitTrackOpticsVideo({
    onSuccess: updateVideoList
  });

  const isFullFilled = [
    workoutAnalysisFormState.limbId,
    workoutAnalysisFormState.strideId,
    workoutAnalysisFormState.injurySeverityId
  ].every(Boolean);
  const isUnusable =
    workoutAnalysisFormState.injuryEntryId ===
    TRACK_OPTICS_INJURY_ENTRY_UNUSABLE_ID;
  const isNoneWithStride =
    workoutAnalysisFormState.injuryEntryId ===
      TRACK_OPTICS_INJURY_ENTRY_NONE_ID && workoutAnalysisFormState.strideId;

  const shouldRenderSeverity =
    workoutAnalysisFormState.limbId && workoutAnalysisFormState.strideId;
  const shouldRenderComment = [isUnusable, isNoneWithStride, isFullFilled].some(
    Boolean
  );
  const shouldRenderHorseName = isNoneWithStride || isFullFilled;
  const isActiveSubmitButton = [
    isUnusable,
    isNoneWithStride,
    isFullFilled
  ].some(Boolean);

  const onChangeField = useCallback(
    (key: WorkoutAnalysisFormField, id: string) => {
      dispatch({ type: key, payload: id });
    },
    [dispatch]
  );

  const handleChangeSearchValue = (value: string) => {
    dispatch({
      type: WorkoutAnalysisFormField.HORSE_NAME_OR_REGISTRATION_NUMBER,
      payload: value
    });
  };

  const handleChangeSeverity = (value: number | string) => {
    const valueToDispatch = value ? value : "";
    dispatch({
      type: WorkoutAnalysisFormField.INJURY_SEVERITY_ID,
      payload: String(valueToDispatch)
    });
  };

  const handleChangeComment = value => {
    dispatch({
      type: WorkoutAnalysisFormField.COMMENT,
      payload: value
    });
  };

  const handleSubmitForm = async () => {
    await submitForm({
      ...workoutAnalysisFormState,
      trackOpticsVideoId: trackOpticsVideo.id
    });
    showSuccessSnack(`Analysis for ${trackOpticsVideo?.videoId} Submitted`);
  };

  const handleFocusInput = e => {
    setupTouchMoveListener(e.target, ETouchMoveListenerAction.ADD);
  };

  const handleBlurInput = e => {
    setupTouchMoveListener(e.target, ETouchMoveListenerAction.REMOVE);
  };

  if (isLoading) return <Loader className={classes.loader} />;
  if (!data) return <></>;

  return (
    <div className={classes.formContainer}>
      <InjuryFields
        data={data}
        workoutAnalysisFormState={workoutAnalysisFormState}
        onChangeField={onChangeField}
      />
      {shouldRenderSeverity && (
        <ButtonsGroup
          className={classes.buttonGroup}
          classes={{ header: classes.header }}
          title={"Injury Severity"}
          name={"Injury Severity"}
          buttons={mapGroupButtonsOptions(
            data.trackOpticsVideoInjurySeverities
          )}
          selectedValue={workoutAnalysisFormState.injurySeverityId}
          onClickButton={handleChangeSeverity}
        />
      )}
      {shouldRenderHorseName && (
        <>
          <div className={classes.entryHeader}>Horse Name</div>
          <AppSearch
            className={clsx(classes.field, classes.searchField)}
            placeholder={SEARCH_BOX_PLACEHOLDER}
            withCheckMarkButton={true}
            showClearSearchButton={true}
            classNames={{
              searchIcon: classes.searchIcon,
              clearIcon: classes.clearIcon,
              input: classes.input,
              itemsList: classes.itemsList
            }}
            onSearch={handleChangeSearchValue}
            onFocus={handleFocusInput}
            onBlur={handleBlurInput}
          />
        </>
      )}
      {shouldRenderComment && (
        <CommentField
          title={"Comments"}
          optionalTitle={"Optional"}
          placeholder={"Add any information you'd like us to know"}
          textareaFontSize={14}
          textareaClassName={classes.commentTextarea}
          value={workoutAnalysisFormState.comment}
          onChange={handleChangeComment}
        />
      )}

      <Button
        className={classes.submitButton}
        variant={"outlined"}
        disabled={!isActiveSubmitButton}
        onClick={handleSubmitForm}
      >
        Submit Analysis
      </Button>
    </div>
  );
};

export default React.memo(WorkoutAnalysisForm);
