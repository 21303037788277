import { racehorse360 } from "@tsg/1st-grpc-web";

type IStringMatchFiled = {
  [key in keyof racehorse360.IStringMatch]: string;
};

export const getRequiredValue = <T>(value: T, defaultValue: T): T =>
  value || defaultValue;

export const getStringMatchQueryField = (
  field: keyof racehorse360.IStringMatch,
  value: string,
  defaulValue = null
): IStringMatchFiled | null => {
  return value ? { [field]: value } : defaulValue;
};
