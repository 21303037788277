import React from "react";

import { EColumn } from "pages/WorkoutsPage/Vet/WorkoutsTable/WorkoutRow/helper";
import WorkoutHistoryModal from "pages/WorkoutsPage/Vet/WorkoutsTable/WorkoutRow/WorkoutHistoryModal";
import RiskLevelCell from "./RiskLevelCell/RiskLevelCell";
import BarnCell from "./BarnCell/BarnCell";
import CsaCell from "./CsaCell/CsaCell";
import DaysSinceEventCell from "./DaysSinceEventCell/DaysSinceEventCell";
import VetListCell from "./VetListCell/VetListCell";
import RiskFactorsCell from "./RiskFactorsCell/RiskFactorsCell";
import TableCellWrapper from "../../TableCellWrapper/TableCellWrapper";
import { IExamsTableRowData, ITableColumnsContent } from "../../types";

interface IProps {
  rowData: IExamsTableRowData;
  columns: string[];
  customColumnsContent?: ITableColumnsContent;
  bold?: boolean;
  extenderFitsTwoColumns?: boolean;
  extenderFitsOneColumns?: boolean;
}

const RowContent = (props: IProps) => {
  const {
    columns,
    rowData,
    customColumnsContent = {},
    bold = false,
    extenderFitsTwoColumns = false,
    extenderFitsOneColumns = false
  } = props;
  const { eventId, eventDate, pastEventsCount, horse, highSpeedFurlongs } =
    rowData;

  const baseColumnsContent: ITableColumnsContent = {
    [EColumn.HISTORY]: (
      <TableCellWrapper key={`${eventId}-history`} centered>
        {Boolean(pastEventsCount) && (
          <WorkoutHistoryModal horse={horse} sourceDate={eventDate} />
        )}
      </TableCellWrapper>
    ),
    [EColumn.RISK_LEVEL]: (
      <RiskLevelCell
        key={`${eventId}-risk-level`}
        bold={bold}
        healthWatchStatus={horse.healthWatchStatus}
      />
    ),
    [EColumn.BARN]: (
      <BarnCell key={`${eventId}-barn`} bold={bold}>
        {horse.barn?.name}
      </BarnCell>
    ),
    [EColumn.CSA]: <CsaCell key={`${eventId}-csa`} bold={bold} horse={horse} />,
    [EColumn.HSF]: (
      <TableCellWrapper key={`${eventId}-hsf`} centered bold={bold}>
        {highSpeedFurlongs}
      </TableCellWrapper>
    ),
    [EColumn.DLW]: (
      <DaysSinceEventCell
        key={`${eventId}-dlw`}
        bold={bold}
        lastEventDate={horse.lastWorkoutDate}
      />
    ),
    [EColumn.DLR]: (
      <DaysSinceEventCell
        key={`${eventId}-dlr`}
        bold={bold}
        lastEventDate={horse.lastRaceDate}
      />
    ),
    [EColumn.ACTIVE_VET_LISTS]: (
      <VetListCell key={`${eventId}-active-vet-lists`} horse={horse}>
        {horse.activeVetListsCount}
      </VetListCell>
    ),
    [EColumn.RISK_FACTORS]: (
      <RiskFactorsCell
        key={`${eventId}-risk-factors`}
        risksFactors={horse.riskFactors}
        horseName={horse.name}
        horseId={horse.id}
      />
    ),
    [EColumn.EXTENDER]: (
      <TableCellWrapper
        key={`${eventId}-extender`}
        lastTwoColumnsCell={extenderFitsTwoColumns}
        lastOneColumnCell={extenderFitsOneColumns}
      >
        &nbsp;
      </TableCellWrapper>
    )
  };

  const allColumnsContent: ITableColumnsContent = {
    ...baseColumnsContent,
    ...customColumnsContent
  };

  return columns.map(column => allColumnsContent[column]);
};

export default React.memo(RowContent);
