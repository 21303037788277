import { racehorse360 } from "@tsg/1st-grpc-web";
import addMonths from "date-fns/addMonths";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { IFilterDataItem } from "components/Filters/filtersOptions";
import {
  IActiveAccordionIndexData,
  IBookData,
  IFilterOptions,
  Periods
} from "./interfaces";
import {
  createAllValuesItem,
  createCustomAllValuesItem
} from "components/Filters/helper";
import { IRadioButtonItem } from "components/Filters/RadioButtonsFilter/RadioButtonsFilter";

export const DIALOG_WIDTH = 400; // max dialog width
const ALL_FILLIES_AND_MARRIES_FILTER_VALUE = 999; // value for all female horses regardless from age

export const ageTitle = {
  [racehorse360.RaceConditionAge.RACE_CONDITION_AGE_TWO]: "Two Year Olds",
  [racehorse360.RaceConditionAge.RACE_CONDITION_AGE_THREE]: "Three Year Olds",
  [racehorse360.RaceConditionAge.RACE_CONDITION_AGE_THREE_PLUS]:
    "Three Year Olds and Upward",
  [racehorse360.RaceConditionAge.RACE_CONDITION_AGE_THREE_OR_FOUR_PLUS]:
    "Three and Four Year Olds",
  [racehorse360.RaceConditionAge.RACE_CONDITION_AGE_FOUR]: "Four Year Olds",
  [racehorse360.RaceConditionAge.RACE_CONDITION_AGE_FOUR_PLUS]:
    "Four Year Olds and Upward",
  [racehorse360.RaceConditionAge.RACE_CONDITION_AGE_THREE_OR_FOUR_OR_FIVE]:
    "Three, Four and Five Year Olds",
  [racehorse360.RaceConditionAge.RACE_CONDITION_AGE_FOUR_OR_FIVE]:
    "Four and Five Years Olds",
  [racehorse360.RaceConditionAge.RACE_CONDITION_AGE_FOUR_OR_FIVE_OR_SIX]:
    "Four, Five and Six Year Olds"
};

export const restrictionsFilterData: IFilterDataItem[] = [
  createAllValuesItem("Restrictions"),
  {
    label: "No Restriction",
    value: String(
      racehorse360.RaceConditionRestriction
        .RACE_CONDITION_RESTRICTION_NO_RESTRICTION
    )
  },
  {
    label: "State Bred Or Sired",
    value: String(
      racehorse360.RaceConditionRestriction
        .RACE_CONDITION_RESTRICTION_STATE_BRED_OR_SIRED
    )
  },
  {
    label: "Other Restriction",
    value: String(
      racehorse360.RaceConditionRestriction.RACE_CONDITION_RESTRICTION_OTHER
    )
  }
];

export const distancesFilterData: IFilterDataItem[] = [
  createAllValuesItem("Distances"),
  {
    label: "Short",
    value: String(
      racehorse360.RaceConditionDistance.RACE_CONDITION_DISTANCE_SHORT
    )
  },
  {
    label: "Long",
    value: String(
      racehorse360.RaceConditionDistance.RACE_CONDITION_DISTANCE_LONG
    )
  }
];

export const agesFilterData: IFilterDataItem[] = [
  createAllValuesItem("Ages"),
  {
    label: "2",
    value: String(racehorse360.RaceConditionAge.RACE_CONDITION_AGE_TWO)
  },
  {
    label: "3",
    value: String(racehorse360.RaceConditionAge.RACE_CONDITION_AGE_THREE)
  },
  {
    label: "3+",
    value: String(racehorse360.RaceConditionAge.RACE_CONDITION_AGE_THREE_PLUS)
  },
  {
    label: "4",
    value: String(racehorse360.RaceConditionAge.RACE_CONDITION_AGE_FOUR)
  },
  {
    label: "4+",
    value: String(racehorse360.RaceConditionAge.RACE_CONDITION_AGE_FOUR_PLUS)
  }
];

export const gendersFilterData: IRadioButtonItem[] = [
  createCustomAllValuesItem("Both"),
  {
    label: "Fillies/Mares",
    value: ALL_FILLIES_AND_MARRIES_FILTER_VALUE
  },
  {
    label: "Open",
    value: racehorse360.RaceConditionGender.RACE_CONDITION_GENDER_OPEN
  }
];

export const defaultConditionBookFiltersState = {
  selectedRestrictions: null,
  selectedDistances: null,
  selectedAge: null,
  selectedGender:
    racehorse360.RaceConditionGender.RACE_CONDITION_GENDER_INVALID,
  selectedConditions: null
};

export const getConditionBookGenderFilterData = (value: number): number[] => {
  if (value) {
    return value === ALL_FILLIES_AND_MARRIES_FILTER_VALUE
      ? [
          racehorse360.RaceConditionGender.RACE_CONDITION_GENDER_FILLIES,
          racehorse360.RaceConditionGender
            .RACE_CONDITION_GENDER_FILLIES_AND_MARES
        ]
      : [value];
  }

  return null;
};

export const getDateRange = (
  period: Periods,
  currentDate: Date
): racehorse360.IRaceConditionFilter => {
  let startDate;
  let endDate;

  switch (period) {
    case Periods.current:
      startDate = currentDate.toISOString();
      break;
    case Periods.pastMonth:
      startDate = addMonths(currentDate, -1).toISOString();
      endDate = currentDate.toISOString();
      break;
    case Periods.past3month:
      startDate = addMonths(currentDate, -3).toISOString();
      endDate = currentDate.toISOString();
      break;
    case Periods.past6month:
      startDate = addMonths(currentDate, -6).toISOString();
      endDate = currentDate.toISOString();
      break;
    case Periods.pastYear:
      startDate = addMonths(currentDate, -12).toISOString();
      endDate = currentDate.toISOString();
      break;
    default:
      startDate = currentDate.toISOString();
  }

  const result = {
    startDateTime: startDate
  };

  if (endDate) {
    result["endDateTime"] = endDate;
  }

  return result;
};

const checkGender = (it: racehorse360.IRaceCondition) => ({
  isMale:
    it.gender === racehorse360.RaceConditionGender.RACE_CONDITION_GENDER_OPEN,
  isFemale:
    it.gender ===
      racehorse360.RaceConditionGender.RACE_CONDITION_GENDER_FILLIES ||
    it.gender ===
      racehorse360.RaceConditionGender.RACE_CONDITION_GENDER_FILLIES_AND_MARES
});

const checkAge = (it: racehorse360.IRaceCondition) => ({
  isTwoYearOlds:
    it.age === racehorse360.RaceConditionAge.RACE_CONDITION_AGE_TWO,
  isThreeYearOlds:
    it.age === racehorse360.RaceConditionAge.RACE_CONDITION_AGE_THREE,
  isThreePlusYearOlds:
    it.age === racehorse360.RaceConditionAge.RACE_CONDITION_AGE_THREE_PLUS ||
    it.age ===
      racehorse360.RaceConditionAge.RACE_CONDITION_AGE_THREE_OR_FOUR_PLUS ||
    it.age ===
      racehorse360.RaceConditionAge.RACE_CONDITION_AGE_THREE_OR_FOUR_OR_FIVE,
  isFourYearOlds:
    it.age === racehorse360.RaceConditionAge.RACE_CONDITION_AGE_FOUR,
  isFourPlusYearOlds:
    it.age === racehorse360.RaceConditionAge.RACE_CONDITION_AGE_FOUR_PLUS ||
    it.age === racehorse360.RaceConditionAge.RACE_CONDITION_AGE_FOUR_OR_FIVE ||
    it.age ===
      racehorse360.RaceConditionAge.RACE_CONDITION_AGE_FOUR_OR_FIVE_OR_SIX
});

export const createModel = (
  result: racehorse360.IListRaceConditionsResponse
): any => {
  const open = "Open";
  const open2yo = "Two Year Olds";
  const open3yo = "Three Year Olds";
  const open3Uyo = "Three Year Olds and Upward";
  const open4yo = "Four Year Olds";
  const open4Uyo = "Four Year Olds and Upward";
  const filliesAndMares = "Fillies and Mares";
  const fillies2yo = "Fillies, Two Year Olds";
  const fillies3yo = "Fillies, Three Year Olds";
  const fillies4yo = "Fillies, Four Year Olds";
  const filliesAndMares3Uyo = "Fillies and Mares, Three Year Olds and Upward";
  const filliesAndMares4Uyo = "Fillies and Mares, Four Year Olds and Upward";

  const model = {
    index: {
      [open]: {
        [open2yo]: [],
        [open3yo]: [],
        [open3Uyo]: [],
        [open4yo]: [],
        [open4Uyo]: []
      },
      [filliesAndMares]: {
        [fillies2yo]: [],
        [fillies3yo]: [],
        [fillies4yo]: [],
        [filliesAndMares3Uyo]: [],
        [filliesAndMares4Uyo]: []
      }
    },
    conditions: {}
  };

  result.raceConditions.forEach(it => {
    const day = it.raceDate;
    if (model.conditions[day]) {
      model.conditions[day] = {
        ...model.conditions[day],
        races: [...model.conditions[day].races, it]
      };
    } else {
      model.conditions[day] = {
        day: it.day,
        date: it.raceDate,
        entriesClose: it.entryCloseDate,
        scratchTime: it.scratchTime,
        races: [it]
      };
    }

    const { isMale, isFemale } = checkGender(it);
    const {
      isTwoYearOlds,
      isThreeYearOlds,
      isThreePlusYearOlds,
      isFourYearOlds,
      isFourPlusYearOlds
    } = checkAge(it);

    if (isMale) {
      if (isTwoYearOlds) {
        model.index[open][open2yo].push(it);
      } else if (isThreeYearOlds) {
        model.index[open][open3yo].push(it);
      } else if (isThreePlusYearOlds) {
        model.index[open][open3Uyo].push(it);
      } else if (isFourYearOlds) {
        model.index[open][open4yo].push(it);
      } else if (isFourPlusYearOlds) {
        model.index[open][open4Uyo].push(it);
      }
    } else if (isFemale) {
      if (isTwoYearOlds) {
        model.index[filliesAndMares][fillies2yo].push(it);
      } else if (isThreeYearOlds) {
        model.index[filliesAndMares][fillies3yo].push(it);
      } else if (isThreePlusYearOlds) {
        model.index[filliesAndMares][filliesAndMares3Uyo].push(it);
      } else if (isFourYearOlds) {
        model.index[filliesAndMares][fillies4yo].push(it);
      } else if (isFourPlusYearOlds) {
        model.index[filliesAndMares][filliesAndMares4Uyo].push(it);
      }
    }
  });

  return model;
};

export const isFiltersEqual = (
  filter1: IFilterOptions,
  filter2: IFilterOptions
): boolean => {
  return Object.keys(filter1).every(key => {
    return (
      filter1[key].length === filter2[key].length &&
      filter1[key].every(it => {
        return filter2[key].includes(it);
      }) &&
      filter2[key].every(it => {
        return filter1[key].includes(it);
      })
    );
  });
};

export const options = [
  {
    id: "0001",
    period: Periods.current,
    name: "Current Book",
    shouldDisplayDate: true
  },
  {
    id: "0002",
    period: Periods.pastMonth,
    name: "Past Month",
    shouldDisplayDate: false
  },
  {
    id: "0003",
    period: Periods.past3month,
    name: "Past 3 Months",
    shouldDisplayDate: false
  },
  {
    id: "0004",
    period: Periods.past6month,
    name: "Past 6 Months",
    shouldDisplayDate: false
  },
  {
    id: "0005",
    period: Periods.pastYear,
    name: "Past Year",
    shouldDisplayDate: false
  }
];

export const dialogViews = {
  facilityListView: "facilityListView",
  dateFilterView: "dateFilterView"
};

export const formatDate = (str: string, pattern: string): string => {
  return format(parseISO(str), pattern);
};

export const getActiveAccordionForIndex = (
  raceId: string,
  indexData: IBookData
): IActiveAccordionIndexData => {
  const genderGroupNames = Object.keys(indexData);
  let currentGenderGroup: string;
  let currentAgeGroup: string;

  Object.values(indexData).forEach((genderGroup, genderGroupIndex) => {
    Object.values(genderGroup).forEach((ageGroup, ageGroupIndex) => {
      const hasIncludesSelectedRace = ageGroup.some(race => race.id === raceId);
      const genderGroupName = genderGroupNames[genderGroupIndex];
      const ageGroupNames = Object.keys(indexData[genderGroupName]);

      if (hasIncludesSelectedRace) {
        currentGenderGroup = genderGroupNames[genderGroupIndex];
        currentAgeGroup = ageGroupNames[ageGroupIndex];
      }
    });
  });

  return {
    genderGroup: currentGenderGroup,
    ageGroup: currentAgeGroup
  };
};
