import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import { racehorse360 } from "@tsg/1st-grpc-web";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import AppPageHeader from "components/AppPageHeader";
import AppSearch from "components/AppSearch/AppSearch";
import { TDesiredEntities } from "components/AppSearch/helper";
import DateRangeSelect from "components/DateRangeSelect";
import ErrorBoundary from "components/ErrorBoundary";
import DateRange, { DateRangeISO, DateRangeType } from "interfaces/DateRange";
import { useTrackOpticsStore } from "pages/TrackOpticsPage/useTrackOpticsStore";
import FacilitySelect from "components/FacilitySelect/FacilitySelect";
import { useLoggedInUser } from "components/LoggedInUserProvider";
import Breakpoints from "common/breakpoints";
import {
  setSelectedDateRange,
  setSelectedFacility
} from "store/actions/trackOpticsPage";

import { ETouchMoveListenerAction, setupTouchMoveListener } from "../helpers";
import useStyles from "./styles";

interface IProps {
  onItemClick: (value: TDesiredEntities) => void;
  onSearch: (value: string, item?: TDesiredEntities) => void;
  clearSearch: boolean;
}

const SEARCH_BOX_PLACEHOLDER = "Search Horses";

const dateOptions = [
  DateRangeType.AllDates,
  DateRangeType.Today,
  DateRangeType.Yesterday,
  DateRangeType.Last3Days,
  DateRangeType.Custom
];

const customOptions = [
  {
    id: "0306d136-c97b-4e13-8c26-f3c21346dce7", //provide random ID to get no videos in response
    name: "Portable",
    code: "PORTABLE",
    backgroundColor: "000000"
  }
];

const TrackOpticsPageHeader = (props: IProps) => {
  const { onItemClick, onSearch, clearSearch } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchesDownSM600 = useMediaQuery(
    theme.breakpoints.down(Breakpoints.SM_600)
  );

  const { selectedFacility, convertedToDateSelectedDateRange } =
    useTrackOpticsStore();
  const { currentUser } = useLoggedInUser();

  const shouldDisplayAllTracksOption: boolean =
    currentUser.vetFacilityIds.length > 1;
  const customFacilities: racehorse360.IFacility[] = currentUser.isChiefVet
    ? customOptions
    : null;

  const handleDateRangeChange = useCallback(
    (dateRange: DateRange) => {
      const storeDateRange: DateRangeISO = {
        Type: dateRange.Type,
        CustomDateRange: []
      };

      if (dateRange.Type === DateRangeType.Custom) {
        storeDateRange.CustomDateRange = dateRange.CustomDateRange.map(date =>
          date.toISOString()
        );
      }
      dispatch(setSelectedDateRange(storeDateRange));
    },
    [dispatch]
  );

  const handleFacilityChange = useCallback(
    (facility: racehorse360.IFacility) => {
      dispatch(setSelectedFacility(facility));
    },
    [dispatch]
  );

  const handleFocusInput = useCallback(
    e => setupTouchMoveListener(e.target, ETouchMoveListenerAction.ADD),
    [setupTouchMoveListener]
  );

  const handleBlurInput = useCallback(
    e => setupTouchMoveListener(e.target, ETouchMoveListenerAction.REMOVE),
    [setupTouchMoveListener]
  );

  return (
    <AppPageHeader className={classes.pageHeader}>
      <div className={classes.internalPageHeaderContainer}>
        <div className={classes.facilityContainer}>
          <ErrorBoundary>
            <FacilitySelect
              isActive={true}
              selectedFacility={selectedFacility}
              onChange={handleFacilityChange}
              shouldDisplayAllTracksOption={shouldDisplayAllTracksOption}
              customOptions={customFacilities}
              shouldDisplayTrainingFacilities={true}
            />
          </ErrorBoundary>
        </div>

        {!matchesDownSM600 && (
          <AppSearch
            className={classes.search}
            placeholder={SEARCH_BOX_PLACEHOLDER}
            onItemClick={onItemClick}
            onSearch={onSearch}
            clearSearch={clearSearch}
          />
        )}

        <div className={classes.allDatesContainer}>
          <ErrorBoundary>
            <DateRangeSelect
              selectedDateRange={convertedToDateSelectedDateRange}
              onChange={handleDateRangeChange}
              defaultSelectedDateRange={DateRangeType.Today}
              dateOptions={dateOptions}
              maxDate={new Date()}
            />
          </ErrorBoundary>
        </div>
      </div>

      {matchesDownSM600 && (
        <AppSearch
          className={classes.mobileViewSearch}
          placeholder={SEARCH_BOX_PLACEHOLDER}
          onItemClick={onItemClick}
          onSearch={onSearch}
          clearSearch={clearSearch}
          onFocus={handleFocusInput}
          onBlur={handleBlurInput}
        />
      )}
    </AppPageHeader>
  );
};

export default React.memo(TrackOpticsPageHeader);
